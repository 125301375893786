import moment from "moment-timezone";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import DateRangePicker from "./DateRangePicker";
import { Card, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import { formateDateTime } from "../../utils/helper";

export const powerStatus = [
  { value: true, label: "Okay" },
  { value: false, label: "Not Okay" },
];
export const motorStatus = [
  { value: true, label: "Working" },
  { value: false, label: "Not Working" },
];
export const deviceInstallationStatus = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];
export const callingStatus = [
  { value: true, label: "Completed" },
  { value: false, label: "Pending" },
];

function CustomToolbar(props) {
  let from = moment(new Date(props?.dateRange?.[0])).format("DD/MM/YYYY");
  let to = moment(new Date(props?.dateRange?.[1])).format("DD/MM/YYYY");

  return (
    <GridToolbarContainer
      style={{
        padding: 10,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Typography color="textPrimary" variant="h6">
        {props?.isDateRange ? (
          <DateRangePicker
            disabled={props?.loading || false}
            placeholderText={"MM/DD/YYYY-MM/DD/YYYY"}
            dateRange={props?.dateRange}
            setDateRange={props?.setDateRange}
          />
        ) : (
          props.title
        )}
      </Typography>
      <GridToolbarExport
        csvOptions={{
          allColumns: true,
          fileName: `${props?.fileName ? props?.fileName?.toUpperCase() : "DISTRICT"
            }${props?.locationName ? "_" + props?.locationName?.toUpperCase() : ""
            }${props?.dateRange[0] !== null ? "_" + from + "—" : ""}${props?.dateRange[1] !== null ? to : ""
            }`,
        }}
      />
    </GridToolbarContainer>
  );
}

const CallingList = (props) => {
  const [tableData, setTableData] = useState([]);

  function getLabelFromStatus({ statusArray, statusValue }) {
    const matchedStatus = statusArray.find(
      (status) => status.value === statusValue
    );
    return matchedStatus ? matchedStatus.label : "";
  }

  useEffect(() => {
    if (!!props?.schemes && Array.isArray(props?.schemes)) {
      let tableDataTemp = [];
      props.schemes.map((page, i) =>
        tableDataTemp.push({
          ...page,
          anurakshak_name:
            page?.anurakshak?.name || page?.other?.op_name || "N/A",
          anurakshak_mobile:
            page?.anurakshak?.mobile_number || page?.other?.op_number || "N/A",
          ...page?.calling_details,
          power_status: getLabelFromStatus({
            statusArray: powerStatus,
            statusValue: page?.calling_details?.power_status,
          }),
          motor_status: getLabelFromStatus({
            statusArray: motorStatus,
            statusValue: page?.calling_details?.motor_status,
          }),
          device_installed: getLabelFromStatus({
            statusArray: deviceInstallationStatus,
            statusValue: page?.calling_details?.device_installed,
          }),
          faulty_site: getLabelFromStatus({
            statusArray: deviceInstallationStatus,
            statusValue: page?.calling_details?.faulty_site,
          }),
          calling_date: formateDateTime(page?.calling_details?.created_ts, ""),
          row: i,
        })
      );
      setTableData(tableDataTemp);
    }
  }, [props?.schemes]);

  const columns = [
    {
      field: "id",
      headerName: "id",
      width: 80,
      sortable: true,
      hide: true,
      headerClassName: "scheme-data-table",
      disableExport: true
    },
    {
      field: "wardId",
      headerName: "Ward Id",
      width: 80,
      sortable: true,
      hide: true,
      headerClassName: "scheme-data-table",
      disableExport: false
    },
    {
      field: "row",
      headerName: "SN.",
      width: 70,
      disableExport: true,
      sortable: true,
      headerClassName: "scheme-data-table",
      renderCell: (params) => {
        return <div>{params.formattedValue + 1}</div>;
      },
    },
    {
      field: "districtName",
      headerName: "District",
      width: 130,
      sortable: true,
    },
    {
      field: "blockName",
      headerName: "Block",
      width: 130,
      sortable: true,
    },
    {
      field: "panchayatName",
      headerName: `Panchayat`,
      width: 130,
      sortable: true,
    },
    {
      field: "name",
      headerName: "Ward",
      width: 130,
      sortable: true,
    },
    {
      field: "anurakshak_name",
      headerName: "Anurakshak Name",
      width: 140,
      sortable: true,
      hide: true,
    },
    {
      field: "anurakshak_mobile",
      headerName: "Anurakshak Mobile",
      width: 130,
      sortable: true,
      hide: true,
    },
    {
      field: "power_status",
      headerName: "Power Status",
      width: 120,
      sortable: true,
      hide: false,
    },
    {
      field: "motor_status",
      headerName: "Motor Status",
      width: 120,
      sortable: true,
      hide: false,
    },
    {
      field: "device_installed",
      headerName: "Is Installed",
      width: 100,
      sortable: true,
      hide: false,
    },
    {
      field: "faulty_site",
      headerName: "Faulty Site",
      width: 100,
      sortable: true,
      hide: false,
    },
    {
      field: "issue_desc",
      headerName: "Issue",
      width: 600,
      sortable: true,
      hide: false,
      renderCell: (params) => {
        return (
          <span
            style={{
              textWrap: "wrap",
            }}
          >
            {params.value}
          </span>
        );
      },
    },
    {
      field: "called_by",
      headerName: "Called By",
      width: 280,
      sortable: true,
      hide: true,
    },
    {
      field: "calling_date",
      headerName: "Calling Date",
      width: 190,
      sortable: true,
      hide: false,
    },
  ];

  return (
    <Card>
      <div style={{ height: 800, width: "100%" }}>
        <DataGrid
          loading={props?.loading || false}
          disableSelectionOnClick
          rows={tableData}
          columns={columns}
          pageSize={40}
          rowsPerPageOptions={[5]}
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{
            toolbar: {
              fileName: props.fileName || "",
              locationName: props.distName || "",
              title: props.title || "",
              dateRange: props?.dateRange,
              setDateRange: props?.setDateRange,
              isDateRange: props?.isDateRange,
              loading: props?.loading,
            },
          }}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          localeText={{ noRowsLabel: "No Records  Found :)" }}
          sx={{
            boxShadow: 0,
            "& .MuiDataGrid-row:hover": {
              color: "primary.main",
            },
          }}
        />
      </div>
    </Card>
  );
};

export default CallingList;
