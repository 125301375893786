import { Button, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import ShowWhen from "./ShowWhen";
import toast, { LoaderIcon } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { uploadReportsPdfORExcel } from "../slices/uploadReports";


const UploadReport = ({ dept = '', divisionName, districtId, selectedFile, setSelectedFile }) => {
  const dispatch = useDispatch();
  const { reportUpload } = useSelector((state) => state.uploadReports);


  const onChangeUploadFile = (e) => {
    const file = e.target.files[0];
    if (file && (file["type"] === "application/pdf" || file["type"] === "application/vnd.ms-excel" || file["type"] === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
      setSelectedFile(file);
    } else {
      toast.error("Please select a PDF or .XLXS file!");
    }
  };

  useEffect(() => {
    if (reportUpload?.status === "SUCCESS") {
      setSelectedFile(null);
    }
  }, [reportUpload, setSelectedFile])


  return (
    <>
      <Typography mt={2}>Upload Non-Functional Report:</Typography>
      <Grid container style={{ marginTop: 20 }}>
        <Grid item md={3}>
          <Button component="label" variant="outlined">
            {selectedFile ? selectedFile?.name : `Select PDF or .XLXS File`}
            <input type="file" onChange={onChangeUploadFile} hidden accept="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
          </Button>
        </Grid>
        <Grid item md={1}>
          <Button
            disabled={
              !selectedFile
              || reportUpload?.status?.toLowerCase() === "loading" || !districtId
            }
            variant="contained"
            onClick={() => {
              dispatch(uploadReportsPdfORExcel({ dept: dept, file: selectedFile, districtId, divisionName }));
            }}
          >
            <ShowWhen
              when={reportUpload?.status?.toLowerCase() === "loading"}
              component={
                <div>
                  <LoaderIcon />
                </div>
              }
              otherwise={"Upload"}
            />
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default UploadReport