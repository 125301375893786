import { Helmet } from "react-helmet-async";
import Placeholder from "../../../components/common/placeholder";
import getTitle from "../../../components/common/Title";

function MobileApp() {
  return (
    <>
      {getTitle({ dept: "Mobile App", })}
      <Placeholder title="Mobile app features available soon!" />
    </>
  );
}

export default MobileApp;
