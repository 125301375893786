import { createSlice } from '@reduxjs/toolkit';
import { API_BASE_URL } from '../config';
import { post } from './service';
import toast from 'react-hot-toast';

const initialState = {
    schemeOperationalData: {
        status: null,
        data: null
    }
};

const slice = createSlice({
    name: 'schemeOperationalData',
    initialState,
    reducers: {
        setSchemeOperationalData(state, action) {
            state.schemeOperationalData = action.payload
        }
    }
});

export const { reducer } = slice;

export const resetOpData = () => async (dispatch) => {
    dispatch(slice.actions.setSchemeOperationalData({ ...initialState.operationalData }));
}

export const getShcemeOperationalData = (location, dateRange) => async (dispatch) => {
    dispatch(slice.actions.setSchemeOperationalData({ ...initialState.schemeOperationalData, status: "loading" }));
    // console.log(">>getShcemeOperationalData", did, divId)
    post(API_BASE_URL + "phed/device_operational_status", { ...location, dateRange })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setSchemeOperationalData(response));
        })
        .catch(function (errResponse) {
            toast(errResponse?.message ? errResponse?.message : "Error in fetching device operational data!", { type: "error" });
            dispatch(slice.actions.setSchemeOperationalData({ ...errResponse, status: "failed" }))
        });
};

export default slice;