import { combineReducers } from '@reduxjs/toolkit';
import { reducer as siteInfo } from '../slices/siteInfo';
import { reducer as firmwareRegistration } from "../slices/firmwareRegistration"
import { reducer as location } from "../slices/location"
import { reducer as schemeReport } from "../slices/schemeReport"
import { reducer as manualTrigger } from "../slices/manual_trigger"
import { reducer as schemesDeviceOperational } from "../slices/schemesDeviceOperational"
import { reducer as deviceStats } from '../slices/deviceStats'
import {reducer as downloadReport} from '../slices/downloadReport'
import {reducer as uploadReports } from  '../slices/uploadReports'
import { reducer as lastPowerOn } from  '../slices/lastPowerOn'

const rootReducer = combineReducers({
  firmwareRegistration,
  location,
  schemeReport,
  manualTrigger,
  siteInfo: siteInfo,
  schemesDeviceOperational,
  deviceStats,
  downloadReport,
  uploadReports,
  lastPowerOn,
});

export default rootReducer;
