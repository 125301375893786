import { useEffect, useState } from "react";
import { convertToFilterDate, } from "../../utils/helper";
import LocationFilter from "../LocationFilter";
import CallingList from "./CallingList";
import { Box, Grid } from "@mui/material";
import { getCallingWardsReports } from "../../actions";
import ShowWhen from "../ShowWhen";
import Loader from "../Loader/Loader";
import getTitle from "./Title";

function CallingReports(props) {
  const [dateRange, setDateRange] = useState([null, null]);
  const [from_Ts, setFrom_Ts] = useState(null);
  const [to_Ts, setTo_Ts] = useState(null);
  const [schemeLocation, setSchemeLocation] = useState({});
  const [loading, setLoading] = useState(false);
  const [callingReportWards, setCallingReportWards] = useState([]);

  const fetchCallingWardsReports = async ({ district, dateRange }) => {
    try {
      setLoading(true);
      setCallingReportWards([]);
      const fetchedComments = await getCallingWardsReports({
        district,
        dateRange: [from_Ts, to_Ts],
        dept: props?.dept,
      });
      setCallingReportWards(fetchedComments.data);
    } catch (error) {
      console.error("Error fetching comments:", error);
    } finally {
      setLoading(false);
    }
  };

  const resetAll = () => {
    setSchemeLocation({});
    setCallingReportWards([]);
  };

  const onChangeLocation = (data) => {
    const keys = Object.keys(data);
    let allReset = true;
    for (let i = 0; i < keys.length; i++) {
      allReset = allReset && !data[keys[i]];
    }

    if (allReset) {
      resetAll();
    }
    setSchemeLocation(data);
  };

  const sendGetSchemeReq = () => {
    let d = {};
    if (schemeLocation.district) {
      d["district"] = schemeLocation.district;
    }
    if (schemeLocation.division) {
      d["division"] = schemeLocation.division;
    }
    if (schemeLocation.block) {
      d["block"] = schemeLocation.block;
    }
    if (schemeLocation.panchayat) {
      d["panchayat"] = schemeLocation.panchayat;
    }
    if (schemeLocation.ward) {
      d["ward"] = schemeLocation.ward;
    }
    if (from_Ts == null && to_Ts == null) {
      fetchCallingWardsReports(d);
    } else if (
      (schemeLocation?.district === null ||
        schemeLocation?.district === undefined ||
        schemeLocation?.district === "") &&
      from_Ts !== null &&
      to_Ts !== null
    ) {
      fetchCallingWardsReports({ ...d, dateRange: [from_Ts, to_Ts] });
    } else {
      fetchCallingWardsReports({ ...d, dateRange: [from_Ts, to_Ts] });
    }
  };

  useEffect(() => {
    if (
      schemeLocation.district ||
      schemeLocation.block ||
      schemeLocation.panchayat ||
      schemeLocation.ward ||
      to_Ts
    ) {
      sendGetSchemeReq();
    }
  }, [schemeLocation, to_Ts]);

  useEffect(() => {
    if (dateRange[0] !== null && dateRange[1] !== null) {
      const from = convertToFilterDate(dateRange[0]);
      const to = convertToFilterDate(dateRange[1], false, true);
      setFrom_Ts(from);
      setTo_Ts(to);
    } else if (dateRange[0] === null && dateRange[1] === null) {
      setFrom_Ts(null);
      setTo_Ts(null);
    }
  }, [dateRange]);

  useEffect(() => {
    resetAll();
  }, []);

  return (
    <>
      {
        getTitle({ dept: props?.dept, end: "Calling Reports" })
      }
      <Box sx={{ pb: 2 }}>
        <LocationFilter
          onChange={onChangeLocation}
          initialValues={schemeLocation}
          showDistrictOnly={true}
          downloadExcel={true}
          removeOptions={["division"]}
          dept={props?.dept}
        />
      </Box>
      <Grid container spacing={3}>
        <Grid item xl={12} md={12} xs={12}>
          <CallingList
            sx={{ height: "100%" }}
            schemes={callingReportWards || []}
            fileName={
              schemeLocation?.district === null ||
                schemeLocation?.district === undefined ||
                schemeLocation?.district === ""
                ? "All_District"
                : callingReportWards[0]?.districtName
            }
            distName={"Calling_reports"}
            dateRange={dateRange}
            setDateRange={setDateRange}
            isDateRange={true}
            loading={loading}
          />
        </Grid>
      </Grid>
      <ShowWhen when={loading} component={<Loader />} />
    </>
  );
}

export default CallingReports;
