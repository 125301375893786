import moment from "moment";
import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { Typography } from "@mui/material";
import ShowWhen from "../ShowWhen";

export default function CustomToolbar(props) {
    let date = moment(new Date()).format("DD_MM_YYYY_HH_mm_ss");
    const isExport = props?.disableExport ? false : true
    return (
      <GridToolbarContainer
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography color="textPrimary" variant="h6">
         {props?.title ? props?.title : "Scheme details"}
        </Typography>
        <ShowWhen when={isExport} component={
        <GridToolbarExport
          csvOptions={{
            allColumns: true,
            fileName: `${props?.fileName ? props?.fileName?.toUpperCase() : "FILENAME"}${props?.locationName ? "_" + props?.locationName?.toUpperCase() : ""}_${date?.toUpperCase()}`,
          }}
        />} />
      </GridToolbarContainer>
    );
  }