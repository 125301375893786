export const getItem = (key) => {
    try {
        return window.localStorage.getItem(key);
    } catch (error) {
        console.error(`Error getting item ${key} from localStorage`, error);
        return null;
    }
};

export const setItem = (key, value) => {
    try {
        window.localStorage.setItem(key, value);
    } catch (error) {
        console.error(`Error setting item ${key} in localStorage`, error);
    }
};

export const clearStorage = () => {
    try {
        window.localStorage.clear();
    } catch (error) {
        console.error('Error clearing localStorage', error);
    }
};