import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { authApi } from '../authAPI';
import toast from 'react-hot-toast';
import { clearStorage, getItem, setItem } from '../utils/localStorageUtils';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  status: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      status: isAuthenticated ? "loggedin" : "not authenticated"
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user,
      status: "loggedin"
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    status: "logged out"
  }),
  REGISTER: (state, action) => {
    const { regStatus } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      regStatus
    };
  }
};

const reducer = (state, action) => handlers[action.type]
  ? handlers[action.type](state, action)
  : state;

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = getItem('accessToken');

        if (accessToken) {
          const user = await authApi.me(accessToken);
          dispatch({
            type: 'INITIALIZE',
            payload: { isAuthenticated: true, user }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: { isAuthenticated: false, user: null }
          });
        }
      } catch (err) {
        console.error('Failed to initialize authentication:', err);
        toast.error('Failed to initialize authentication!', err?.message);
        dispatch({
          type: 'INITIALIZE',
          payload: { isAuthenticated: false, user: null }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    try {
      const { token } = await authApi.login({ email, password });
      const user = await authApi.me(token);

      setItem('accessToken', token);
      setItem('USER_TYPE', user?.userType?.toUpperCase());
      setItem('USER_STATUS', user?.userStatus);
      setItem('USER_DEPT', user?.dept?.toUpperCase());

      dispatch({
        type: 'LOGIN',
        payload: { user }
      });

      window.location.reload();
    } catch (error) {
      console.error('Login failed:', error);
      toast.error('Login failed!', error?.message);
    }
  };

  const logout = async () => {
    clearStorage();
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (data) => {
    try {
      const regStatus = await authApi.register(data);
      dispatch({
        type: 'REGISTER',
        payload: { regStatus }
      });
    } catch (error) {
      console.error('Registration failed:', error);
      toast.error('Registration failed!', error?.message);
    }
  };

  return (
    <AuthContext.Provider value={{ ...state, platform: 'JWT', login, logout, register }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;