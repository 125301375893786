import {
  Box,
  Container,
  Typography,
} from "@mui/material";
import logo from "../images/logo_black.png";

const AuthBanner = () => (
  <Box
    sx={{
      borderBottom: 1,
      borderColor: "divider",
      py: 2,
    }}
  >
    <Container maxWidth="md">
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          width={"9%"}
          src={logo}
          alt="logo"
          style={{
            marginRight: 10,
          }}
        />
        <Box
          sx={{
            alignItems: "bottom",
            display: "flex",
            flexDirection: "column-reverse",
            ml: 2,
            "& > img": {
              height: 30,
              mx: 2,
            },
          }}
        >
          <Typography color="textPrimary" variant="h5">
            <Box sx={{ fontWeight: "bold" }}>HAR GHAR NAL JAL YOJNA</Box>
            <Box sx={{ fontWeight: "bold" }}> Government of Bihar</Box>
          </Typography>
        </Box>
      </Box>
    </Container>
  </Box>
);

export default AuthBanner;
