import { Helmet } from "react-helmet-async";

const getTitle = ({ title = "Rollz Dashboard", dept = "", end = "" } = {}) => {
    if (dept) {
        title += ` | ${dept.toUpperCase()}`;
    }

    if (end) {
        title += ` | ${end}`;
    }

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
        </>
    );
}

export default getTitle