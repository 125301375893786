import { Box, Container, Grid, Typography } from "@mui/material";

const DepartmentLayout = ({ dept, settings, children }) => (
    <Box
        sx={{
            backgroundColor: "background.default",
            minHeight: "100%",
            py: 1,
        }}
    >
        <Container maxWidth={settings?.compact ? "xxl" : false}>
            <Grid container justifyContent="space-between">
                <Grid item width="100%">
                    <Typography color="textPrimary" variant="h5" sx={{ textTransform: "uppercase" }} >
                        {dept}
                    </Typography>
                    <br />
                </Grid>
            </Grid>
            {children}
        </Container>
    </Box>
);


export default DepartmentLayout