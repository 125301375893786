import { createSlice } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../config";
import { get, post } from "./service";
import toast from "react-hot-toast";

const initialState = {
  firmwareData: {
    status: null,
    data: null,
  },
  deleteDeviceData: {
    status: null,
    data: null,
  },
};

const slice = createSlice({
  name: "firmwareData",
  initialState,
  reducers: {
    setFirmwareData(state, action) {
      state.firmwareData = action.payload;
    },
    setDeleteStataus(state, action) {
      state.deleteDeviceData = action.payload;
    },
  },
});

export const { reducer } = slice;

export const getRegisteredDevices =
  ({ filter = null, continuationToken = null }) =>
    async (dispatch) => {
      dispatch(
        slice.actions.setFirmwareData({
          ...initialState.firmwareData,
          status: "loading",
        })
      );
      const payload = {
        ...filter,
        continuationToken,
      };
      get(API_BASE_URL + "phed/debugging/registered-devices", payload)
        .then((rawData) => rawData)
        .then(function (response) {
          response = response || {};
          dispatch(slice.actions.setFirmwareData(response));
        })
        .catch(function (errResponse) {
          dispatch(
            slice.actions.setFirmwareData({ ...errResponse, status: "failed" })
          );
        });
    };

export const deleteDeviceData = (deleteParameters) => async (dispatch) => {
  dispatch(
    slice.actions.setDeleteStataus({
      ...initialState.deleteDeviceData,
      status: "loading",
    })
  );

  post(API_BASE_URL + "phed/debugging/deleteDeviceDetails", deleteParameters)
    .then((rawData) => rawData)
    .then(function (response) {
      response = response || {};
      if (response.statusCode === 200) {
        toast.success(
          response?.message ? response?.message : "Deleted successfully!",
          { duration: 4000 }
        );
        dispatch(slice.actions.setDeleteStataus(response));
      } else {
        toast.error(
          response?.message ? response?.message : "Something went wrong!",
          { duration: 4000 }
        );
        dispatch(
          slice.actions.setDeleteStataus({ ...initialState.deleteDeviceData })
        );
      }
    })
    .catch(function (errResponse) {
      toast.error(
        errResponse?.message ? errResponse?.message : "Something went wrong!",
        { duration: 4000 }
      );
      dispatch(
        slice.actions.setDeleteStataus({ ...errResponse, status: "failed" })
      );
    });
};

export default slice;
