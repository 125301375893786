import getTitle from "../../../components/common/Title"
import Placeholder from "../../../components/common/placeholder"

const DashboardHome = () => {
    return (
        <>
            {
                getTitle({ dept: "Dashboard", end: "Har Ghar Nal Jal Yojna" })
            }
            <Placeholder title="Welcome, To The Rollz Dashboard" />
        </>
    )
}

export default DashboardHome