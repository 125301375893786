import { Button, Divider, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { priColor } from "../../constants";
import { getDistrict, getDivision } from "../../slices/location";
import { } from "../../slices/schemeReport";
import { historyDate } from "../../utils/helper";
import * as Yup from "yup";
import Loader from "../Loader/Loader";
import UploadReport from "../UploadReport";
import getTitle from "./Title";
import { getOperationalDataHandler, updateAvgSchemeStatsHandler } from "./NonFunctionalReports";
import ShowWhen from "../ShowWhen";
import { getAvgSchemeStats } from "../../actions";
import toast from "react-hot-toast";

function decimalFormat(v) {
  if (Number(v)) {
    return v.toFixed(2);
  } else {
    return v;
  }
}

const fields = [
  {
    name: "Functional Schemes",
    fname: "functional",
    ext: "(No's)",
  },
  { name: "Non Functional Schemes", fname: "nonFunctional", ext: "(No's)" },
  {
    name: "Non Reporting Schemes",
    fname: "nonReporting",
    ext: "(No's)",
    // disabled: true,
    note: "(Non Reporting Schemes = Total Schemes – Functional Schemes – Non Functional Schemes)",
  },
  {
    name: "Avg Pump Running",
    fname: "avgMotorRunning",
    ext: "(Hrs)",
    note: "(Decimals Allowed, This can not be > 20Hrs and This can not be = 0Hrs.)",
  },
  {
    name: "Avg Electricity",
    fname: "avgElectricity",
    ext: "(Hrs)",
    note: "(Decimals Allowed, This can not be > 24Hrs, This can not be < 5Hrs.)",
  },
  {
    name: "Avg Water Consumption",
    fname: "avgWaterConsumption",
    ext: "(KL)",
    note: "(Decimals Allowed, This can not be > 120 KL, This can not be < 70 KL)",
    disabled: true,
  },
];

function SchemeData({ dept = '', menuId }) {
  const [currentDistrict, setCurrentDistrict] = useState(0);
  const [currentDivision, setCurrentDivision] = useState(0);
  const [allDivision, setAllDivision] = useState([]);
  const [allDist, setAllDist] = useState([]);
  const [divisionName, setDivisionName] = useState(null)
  const [selectedFile, setSelectedFile] = useState(null);
  const [sumOfFUN_NF_NR, setSumOfFUN_NF_NR] = useState(0);
  const [tempStats, setTempStats] = useState(null);
  const [loading, setLoading] = useState(null);
  const [updated, setUpdated] = useState(false);
  const [avgSchemeStats, setAvgSchemeStats] = useState(null)


  const dispatch = useDispatch();
  const { district, division } = useSelector((state) => state.location);
  const [formData, setFormData] = useState(null);
  const [actualValues, setActualValues] = useState({});

  const handleDistrictChange = function (e) {
    setCurrentDivision(0)
    setCurrentDistrict(e.target.value);
    dispatch(getDivision(e.target.value, dept));
    setSelectedFile(null)
  };

  const handleDivisionChange = function (e) {
    setCurrentDivision(e.target.value);
    setSelectedFile(null)
  };

  useEffect(() => {
    setFormData(null)
  }, [])

  useEffect(() => {
    let initialVal = {
      functional: 0,
      nonFunctional: 0,
      nonReporting: 0,
      avgMotorRunning: 0,
      avgElectricity: 0,
      avgWaterConsumption: 0,
    };
    if (tempStats) {
      if (tempStats?.data) {
        let tempActualValues = { ...actualValues };
        setFormData({ ...tempActualValues, ...tempStats?.data });
      } else {
        let tempFormData = { ...formData };
        setFormData({ ...tempFormData, ...initialVal });
      }
    }

    if (tempStats?.actualData) {
      let tempActualValues = { ...actualValues };
      setActualValues({
        ...tempActualValues,
        ...tempStats?.actualData,
      });
    } else {
      let tempActualValues = { ...actualValues };
      setActualValues({
        ...tempActualValues,
        ...initialVal,
      });
    }
  }, [tempStats]);

  useEffect(() => {
    if (avgSchemeStats) {
      let initialVal = {
        avgMotorRunning: 0,
        avgElectricity: 0,
        avgWaterConsumption: 0,
      };
      if (avgSchemeStats?.actualData) {
        let tempActualValues = { ...actualValues };
        setActualValues({ ...tempActualValues, ...avgSchemeStats.actualData });
      } else {
        let tempActualValues = { ...actualValues };
        setActualValues({ ...tempActualValues, ...initialVal });
      }
    }
  }, [avgSchemeStats]);

  useEffect(() => {
    dispatch(getDistrict({ dept }));
  }, []);

  useEffect(() => {
    if (district.data) setAllDist(district.data);
  }, [district]);

  useEffect(() => {
    if (division.data) setAllDivision(division.data);
  }, [division]);

  const getAvgSchemeStatsHandler = async (did, divid, dateRange) => {
    try {
      setLoading(true);
      const avgSchemesRes = await getAvgSchemeStats({ did: did, divid: divid, dateRange: dateRange, });
      if (avgSchemesRes) {
        setAvgSchemeStats(avgSchemesRes)
      }
    } catch (error) {
      toast.error(`Error in fetching avg schemes stats: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = () => {
    if (currentDistrict) {
      getOperationalDataHandler({ district: currentDistrict, division: currentDivision, setLoading: setLoading, dept, setTempStats, isOnlyTempData: false })
      getAvgSchemeStatsHandler(currentDistrict, currentDivision, historyDate(1))
    }
  };

  useEffect(() => {
    const filteredDist = allDist.filter((d) => Number(d.id) === Number(currentDistrict))
    setDivisionName(filteredDist[0]?.name);
    fetchData();
  }, [currentDistrict, currentDivision, updated]);

  // useEffect(() => {
  //   fetchData();
  // }, [updated]);


  return (
    <>
      {getTitle({ dept: dept, end: "Scheme Data" })}
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Grid container>
          <Grid item md={12}>
            <Grid container spacing={3}>
              <Grid item md={2}>
                <TextField
                  fullWidth
                  label="District"
                  name="district"
                  onChange={handleDistrictChange}
                  select
                  SelectProps={{ native: true }}
                  value={currentDistrict.id}
                  variant="outlined"

                >
                  {[{ id: 0, name: "" }, ...allDist].sort((a, b) => a.name.localeCompare(b.name)).map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name?.toLocaleUpperCase()}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item md={2}>
                <TextField
                  fullWidth
                  label="Division"
                  name="division"
                  onChange={handleDivisionChange}
                  select
                  SelectProps={{ native: true }}
                  value={currentDivision}
                  variant="outlined"
                >
                  {[{ id: 0, name: "" }, ...allDivision].map((category) => (
                    <option key={category.id} value={category.id}>
                      {category?.name?.toLocaleUpperCase()}
                    </option>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item >
            {formData && currentDistrict ?
              (
                <>
                  <Formik
                    enableReinitialize
                    initialTouched={{ zip: true }}
                    initialValues={{
                      ...formData,
                      submit: null,
                    }}
                    validationSchema={() => Yup.lazy((values) => {
                      setSumOfFUN_NF_NR(Number(values.functional) + Number(values.nonFunctional) + Number(values.nonReporting));
                      return Yup.object().shape({
                        functional: Yup.number()
                          .integer()
                          .min(1, "Must be greater than 0")
                          .max(formData["total"], `Should be less than or equal to ${formData["total"]}`
                          )
                          .required("Cannot be empty"),
                        nonFunctional: Yup.number()
                          .integer()
                          .min(0, "Must be greater than or equal to 0")
                          .max((values["total"] - values["functional"]) >= 0 ? values["total"] - values["functional"] : 0)
                          .required("Cannot be empty"),
                        nonReporting: Yup.number()
                          .integer()
                          .min(0, "Must be greater than or equal to 0")
                          .max((values["total"] - values["functional"] - values["nonFunctional"]) >= 0 ? (values["total"] - values["functional"] - values["nonFunctional"]) : 0)
                          .required("Cannot be empty"),
                        avgMotorRunning: Yup.number().min(1).max(20).required(),
                        avgElectricity: Yup.number().min(5).max(24).required(),
                      })
                    })
                    }
                    onSubmit={async (
                      values,
                      { setErrors, setStatus, setSubmitting }
                    ) => {
                      try {
                        let finalVal = {
                          ...values,
                          avgWaterConsumption: Number(values["avgMotorRunning"]) * 3 * 60 * 60,
                          division: allDivision[0]?.id,
                          dept
                        };

                        await updateAvgSchemeStatsHandler({ district: currentDistrict, ...finalVal }, setLoading, updated, setUpdated)
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      touched,
                      values,
                    }) => (
                      <form noValidate onSubmit={handleSubmit} >
                        <Grid container spacing={2} sx={{ mt: 0 }}>
                          <Grid item md={12}>
                            <Typography>
                              Total Schemes:
                              <span
                                style={{ color: priColor, fontWeight: "bold" }}
                              >
                                {" "}
                                {values["total"]} (No's)
                              </span>
                            </Typography>
                          </Grid>
                          {fields.map((fe, i) => {
                            let component = null;
                            if (fe.fname === "avgWaterConsumption") {
                              component = (
                                <Grid item md={6} key={i}>
                                  <TextField
                                    fullWidth
                                    label={fe.name}
                                    disabled={true}
                                    name={fe.fname}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    value={Number(values["avgMotorRunning"]) ? Number(values["avgMotorRunning"]) * 3 * 60 * 60 : 0}
                                    variant="outlined"
                                  />
                                </Grid>
                              );
                            } else {
                              component = (
                                <Grid item md={6} key={i}>
                                  <TextField
                                    autoFocus
                                    error={Boolean(
                                      touched[fe.fname] && errors[fe.fname]
                                    )}
                                    fullWidth
                                    helperText={
                                      touched[fe.fname] && errors[fe.fname] ? (
                                        errors[fe.fname]
                                      ) : (
                                        <span style={{ color: priColor }}>
                                          {fe.note}
                                        </span>
                                      )
                                    }
                                    label={fe.name}
                                    name={fe.fname}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="text"
                                    value={values[fe.fname]}
                                    variant="outlined"
                                  />
                                </Grid>
                              );
                            }
                            return (
                              <>
                                {component}
                                <Grid item md={6} alignContent="center">
                                  <Typography
                                    sx={{ pt: 4, fontWeight: "bold" }}
                                    color={priColor}
                                  >{`${fe.fname === "avgWaterConsumption"
                                    ? decimalFormat(
                                      actualValues[fe.fname] / 1000
                                    )
                                    : actualValues[fe.fname]
                                    } ${fe.ext}`}</Typography>
                                </Grid>
                              </>
                            );
                          })}
                          <Grid item md={12} alignItems="center">
                            <Button variant="contained" type="submit" disabled={(sumOfFUN_NF_NR > formData.total)}>
                              Change Values
                            </Button>
                            <Button
                              variant="outlined"
                              sx={{ ml: 2 }}
                              onClick={async () => {
                                await updateAvgSchemeStatsHandler({ district: currentDistrict, ...actualValues, division: allDivision[0]?.id, dept }, setLoading, updated, setUpdated)
                              }}
                            >
                              Reset to Actual Values
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    )}
                  </Formik>
                  <br />
                  <Divider />
                  <UploadReport dept={dept} selectedFile={selectedFile} setSelectedFile={setSelectedFile} districtId={currentDistrict} divisionName={divisionName} />
                </>
              ) : undefined
            }
          </Grid>
        </Grid>
        <ShowWhen when={loading} component={<Loader />} />
      </Box>
    </>
  );
}

export default SchemeData;
