import { Box, Button, ButtonGroup } from "@mui/material";
import { useState } from "react";
import SchemeData from "./SchemeData";
import TriggerDeviceEvent from "./TriggerDeviceEvent";
import ReportsButtonGroup from "./ReportsButtonGroup";
import CallingReports from "./CallingReports";

const CommonButtonGroup = ({ menus, renderContent }) => {
    const [menuId, setMenuId] = useState(0);

    return (
        <>
            <ButtonGroup variant="contained" aria-label="outlined primary button group">
                {menus.map((menu, index) => (
                    <Button
                        key={index}
                        color="primary"
                        variant={menuId === index ? "contained" : ""}
                        onClick={() => setMenuId(index)}
                    >
                        {menu}
                    </Button>
                ))}
            </ButtonGroup>
            <Box sx={{ mt: 2 }}>
                {renderContent(menuId)}
            </Box>
        </>
    );
};


export default CommonButtonGroup


export const createRenderContent = (dept, titleForPdf) => (menuId) => {
    switch (menuId) {
        case 0: return <SchemeData dept={dept} />;
        case 1: return <TriggerDeviceEvent dept={dept} />;
        case 2: return <ReportsButtonGroup dept={dept} titleForPdf={titleForPdf} />;
        case 3: return <CallingReports dept={dept} />;
        default: return null;
    }
};
