import PropTypes from "prop-types";

const BadgePills = ({ className, status, backgroundColor, color, width = "10px", height = "10px", borderRadius = "30px", padding = "0px"  }) => {
  return (
    <>
      <span
        className={className}
        style={{
          backgroundColor,
          color,
          width,
          height,
          borderRadius,
          padding : status && padding
        }}
      >
        {status}
      </span>
    </>
  );
};

export default BadgePills;

BadgePills.propTypes = {
  className: PropTypes.any,
  status: PropTypes.bool,
  bgColor: PropTypes.any,
  text: PropTypes?.string,
};
