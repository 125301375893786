import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider as ReduxProvider } from "react-redux";
import { AuthProvider } from "./contexts/JWTContext";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import store from "./store";
import { ThemeProvider, createTheme } from "@mui/material";
import { Toaster } from 'react-hot-toast';

const theme = createTheme();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <BrowserRouter>
            <AuthProvider>
              <Toaster />
              <App />
            </AuthProvider>
          </BrowserRouter>
        </ReduxProvider>
      </HelmetProvider>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
