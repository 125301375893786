import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Divider, Drawer, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import useAuth from "../../hooks/useAuth";
import NavSection from "../../components/NavSection";
import Scrollbar from "../../components/Scrollbar";
import { checkIfRoleExist } from "../../utils/helper";

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const location = useLocation();
  const { user } = useAuth();

  const sections = useMemo(() => {
    const defaultSections = [
      {
        title: "General",
        items: [{ title: "HOME", path: "/dashboard" }],
      },
    ];

    if (!user || !user.userType) return defaultSections;

    if (checkIfRoleExist(user.userType, ["APP_SUPER_ADMIN"])) {
      defaultSections[0].items.push(
        { title: "PHED", path: "/dashboard/phed" },
        { title: "UDHD", path: "/dashboard/udhd" },
        { title: "PRD", path: "/dashboard/prd" },
        { title: "Firmware", path: "/dashboard/firmware" },
        { title: "User Management", path: "/dashboard/users-management" },
        { title: "MOBILE APP", path: "/dashboard/mobile-app" },
      );
    }

    if (checkIfRoleExist(user.userType, ["APP_ADMIN"]) && user.dept) {
      user.dept
        .toUpperCase()
        .split(".")
        .forEach((dept) => {
          if (["PHED", "UDHD", "PRD"].includes(dept)) {
            defaultSections[0].items.push({
              title: dept,
              path: `/dashboard/${dept.toLowerCase()}`,
            });
          }
        });
    }

    return defaultSections;
  }, [user]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);


  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{ "& + &": { mt: 3 } }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      open={!isMobile || openMobile}
      onClose={isMobile ? onMobileClose : undefined}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          height: "calc(100% - 64px) !important",
          top: "64px !Important",
          width: 280,
        },
      }}
      variant={isMobile ? "temporary" : "permanent"}
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
