import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Card } from "@mui/material";
import CustomNoRowsOverlay from "./common/CustomNoRowsOverlay";
import CustomToolbar from "./common/CustomToolbar";


const DeadWardList = (props) => {
  const [tableData, setTableData] = useState([]);
 

  useEffect(() => {
    let tableDataTemp = [];
    if (props.schemes && props.schemes.length) {
      props.schemes.map((page, i) => {
        tableDataTemp.push({
          ...page,
          ...page?.other,
          did: page?.other?.deviceid,
          wname: page?.name,
          lastOperatedOn: page?.lastHearBeatDate ? page?.lastHearBeatDate : "" ,
          id: page?.id,
          slno: i + 1,
        });
      });
    }

    setTableData(tableDataTemp);
  }, [props.schemes]);

  const columns = [
    {
      field: "slno",
      headerName: "Sl No",
      width: 80,
      sortable: true,
      headerClassName: "scheme-data-table",
    },
    {
      field: "id",
      hide: true,
      width: 130,
      sortable: true,
      headerClassName: "scheme-data-table",
    },
    {
      field: "districtName",
      headerName: "Distric",
      width: 130,
      sortable: false,
    },
    {
      field: "blockName",
      headerName: "Block",
      width: 130,
      sortable: true,
    },
    {
      field: "panchayatName",
      headerName: "Panchayat",
      width: 130,
      sortable: true,
    },
    {
      field: "wname",
      headerName: "Ward",
      width: 130,
      sortable: false,
    },
    {
      field: "lastOperatedOn",
      headerName: "Last Operated On",
      width: 210,
      sortable: true,
    },
    {
      field: "barcode",
      headerName: "Barcode",
      width: 150,
      sortable: true,
    },
    {
      field: "deviceId",
      headerName: "Device Id",
      width: 150,
      sortable: true,
    },
  ];

  return (
    <Card>
      <div
        style={{ height: 683, width: "100%" }}
      >
        <DataGrid
          loading={props?.loading || false}
          disableSelectionOnClick
          rows={tableData}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          components={{
            Toolbar: CustomToolbar,
          }}
          componentsProps={{
            toolbar: {
              fileName: props.fileName,
              locationName: props.distName,
              title: props?.title,
              showExport: props?.showExport,
            },
          }}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          localeText={{ noRowsLabel: "Dead Devices Not Found :)" }}
        />
      </div>
    </Card>
  );
};

export default DeadWardList;
