import { useState, useEffect } from "react";
import AuthBanner from "../components/AuthBanner";
import "./LandingPage.scss";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { DASHBOARD_URL } from "../constants";
import toast from "react-hot-toast";
import getTitle from "../components/common/Title";

const LandingPage = () => {
  const [selectedDashboard, setSelectedDashboard] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [height, setHeight] = useState(window.innerHeight);

  const handleDashboardSelection = (e) => {
    setSelectedDashboard(e.target.value);
  };

  const handleProceedClick = () => {
    if (!selectedDashboard) {
      toast.error("Please select dept to proceed!")
    }
    const selectedObj = DASHBOARD_URL.find((e) => e.url === selectedDashboard);
    if (selectedObj && !selectedObj.enable) {
      toast.error(`Features will be enabled soon for ${selectedObj.name}!`)
    } else if (selectedObj) {
      window.location.replace(selectedObj.url);
    }
  };

  return (
    <>
      {
        getTitle({ dept: "har ghar nal jal yojna", end: "Government of Bihar" })
      }
      <Box
        sx={{
          display: "flex",
          backgroundImage: 'url("/bg_image.jpg")',
          flexDirection: "column",
          minHeight: height - 149,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <AuthBanner />
        <Container sx={{ py: "20px" }}>
          <Box display="flex" justifyContent="center">
            <Card
              sx={{
                borderRadius: "16px",
                backgroundColor: "rgba(255,255,255,0.5)",
              }}
            >
              <CardContent sx={{ display: "flex", flexDirection: "column", pl: 0 }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" flexDirection="column" color="white" mb={2} mt={2}>
                      <Box
                        sx={{ width: "60%", p: 2, textAlign: "left", backgroundColor: "rgba(255,0,102,0.5)" }}
                      >
                        <Typography variant="h5">Welcome</Typography>
                        <Typography variant="h5">Geron User</Typography>
                      </Box>
                    </Box>
                    <Box className="chooseDashboard">
                      <Typography variant="h6">
                        <Box sx={{ fontWeight: "bold" }} className="title">
                          Choose your Dashboard
                        </Box>
                      </Typography>
                      <FormControl sx={{ mt: 1 }} onChange={handleDashboardSelection}>
                        <RadioGroup aria-labelledby="dashboard-radio-group-label" name="radio-buttons-group">
                          {DASHBOARD_URL.map((dbe, key) => (
                            <FormControlLabel
                              key={key}
                              value={dbe.url}
                              control={<Radio style={{ color: "#FF0066" }} />}
                              label={dbe.name}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Box>

                    <Box textAlign="center" mt={2}>
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "#FF0066", width: "50%", borderRadius: 16, fontWeight: "bold" }}
                        onClick={handleProceedClick}
                      >
                        Proceed
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
          <Box display="flex" justifyContent="center" mt={2} fontSize={14}>
            <Typography>
              Powered by <b>Geron Engineering Private Limited &#174;</b>
            </Typography>
          </Box>
        </Container>
      </Box>
      <footer>
        <Box
          sx={{
            flexShrink: 0,
            p: 1,
            fontSize: 14,
            backgroundColor: "rgba(0,0,0,0.4)",
            color: "white",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>
            CONTACT INFO
            <br />
            Address:
            <br />
            Geron Engineering Private Limited
            <br />
            P-4, Bulandshahr Road, Industrial Area,
            <br />
            Ghaziabad, Uttar Pradesh – 201009
            <br />
            Tel: 0120 - 4565999
            <br />
            Mail: info@geronengineering.com
          </Box>
          <Box display="flex" flexDirection="column-reverse">
            <Link underline="none" color="inherit" href="/authentication/login" className="adminLink">
              Admin
            </Link>
          </Box>
        </Box>
      </footer>
    </>
  );
};

export default LandingPage;
