import { Box, Button, Grid, Typography, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSchemes, resetSchemes } from "../../slices/siteInfo";
import LocationFilter from "../LocationFilter";
import {
  capitalizedWordsFirstLetterWithSpace,
  generateCapitalizedFilename,
  get24HoursTimeStamp,
  historyDate,
  removeDuplicate,
  updateFinalNFWList,
  syncNFLists,
  syncAccordionData,
} from "../../utils/helper";
import ShowWhen from "../ShowWhen";
import Loader from "../Loader/Loader";
import WardList from "../WardList";
import CustomAccordion from "./CustomAccordian";
import MyDocument from "../PdfGeneratorDocument";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DeadWardList from "../DeadWardList";
import { priColor } from "../../constants";
import { generateNFReport, getCurrentNFW, getDeadDevices, getOperationalData, getReports, updateAvgSchemeStats } from "../../actions";
import toast from "react-hot-toast";
import getTitle from "./Title";

export const getReportsHandler = async (type, did, setLoading, options = {}) => {
  const {
    setFetchedActualFW = () => { },
    setFetchedActualNRW = () => { },
    setFetchedActualNFW = () => { },
    setFetchedActualNFW_Ids = () => { },
    setFetchedActualNRW_Ids = () => { },
  } = options;

  try {
    setLoading(true);
    const reportRes = await getReports({
      did: did,
      dateRange: get24HoursTimeStamp(1),
      type,
      reportReturn: 1
    });

    const { functionalData, nonReporting, nonFunctionalData } = reportRes?.data || {};

    setFetchedActualFW(functionalData);
    setFetchedActualNRW(nonReporting);
    setFetchedActualNFW(nonFunctionalData);
    setFetchedActualNFW_Ids(nonFunctionalData?.map((wd) => wd.id) || []);
    setFetchedActualNRW_Ids(nonReporting?.map((wd) => wd.id) || []);
  } catch (error) {
    toast.error(`Error fetching reports: ${error.message}`);
  } finally {
    setLoading(false);
  }
};

export const getCurrentNFWHandler = async (district, setLoading, wardType, options = {}) => {
  const {
    setFetchedCurrentNFW = () => { },
    setFetchedCurrentNFW_Ids = () => { },
    setFetchedCurrentNRW = () => { },
    setFetchedCurrentNRW_Ids = () => { },
  } = options;

  try {
    setLoading(true);
    if (!wardType) wardType = "nonFunctionalWards"
    const currentNFWRes = await getCurrentNFW({ did: district, wardType });
    const currentNFWData = currentNFWRes?.data || [];

    setFetchedCurrentNFW(currentNFWData);
    setFetchedCurrentNFW_Ids(currentNFWData.map((item) => item.id));
    setFetchedCurrentNRW(currentNFWData);
    setFetchedCurrentNRW_Ids(currentNFWData.map((item) => item.id));
  } catch (error) {
    toast.error(`Error fetching current non-functional reports: ${error.message}`);
  } finally {
    setLoading(false);
  }
};

export const getOperationalDataHandler = async ({ district, division = 0, setLoading, dept, setTempStats, isOnlyTempData = true }) => {
  try {
    setLoading(true);

    const operationalDataRes = await getOperationalData(
      { district: district, division: division },
      historyDate(3, "currentDay"),
      dept,
      isOnlyTempData
    );

    setTempStats(operationalDataRes);
  } catch (error) {
    toast.error(`Error fetching operational data: ${error.message}`);
  } finally {
    setLoading(false);
  }
};

export const updateAvgSchemeStatsHandler = async (data, setLoading, updated, setUpdated) => {
  try {
    setLoading(true);
    const updateRes = await updateAvgSchemeStats(data);
    if (updateRes) {
      setUpdated(!updated);
    }
  } catch (error) {
    toast.error(`Error in updating temp stats data: ${error.message}`);
  } finally {
    setLoading(false);
  }
};


export default function NonFunctionalReports(props) {
  const dispatch = useDispatch();
  const [schemeLocation, setSchemeLocation] = useState({});
  const { ward, schemes } = useSelector((state) => state.siteInfo);
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);

  // Fetched Data Var
  const [fetchedActual_NFW, setFetchedActualNFW] = useState([]);
  const [fetchedActual_NRW, setFetchedActualNRW] = useState([]);
  const [fetchedActual_FW, setFetchedActualFW] = useState([]);
  const [fetchedCurrent_NFW, setFetchedCurrentNFW] = useState([]);
  const [fetchedNew_NFW, setFetchedNewNFW] = useState([]);

  const [fetchedCurrent_NFW_Ids, setFetchedCurrentNFW_Ids] = useState([]);
  const [fetchedNew_NFW_Ids, setFetchedNewNFW_Ids] = useState([]);
  const [fetchedActual_NFW_Ids, setFetchedActualNFW_Ids] = useState([]);

  // Selected Data Var
  const [selectedActual_NFW, setSelectedActualNFW] = useState([]);
  const [selectedCurrent_NFW, setSelectedCurrentNFW] = useState([]);
  const [selectedNew_NFW, setSelectedNewNFW] = useState([]);
  const [selectedFinal_NFW, setSelectedFinalNFW] = useState([]);
  const [selectedFinal_NFW_Ids, setSelectedFinalNFW_Ids] = useState([]);
  const [deadDevicesTemp, setDeadDevicesTemp] = useState([]);

  const [expanded, setExpanded] = useState(false);
  const [panel, setPanel] = useState("");
  const [tempStats, setTempStats] = useState(null);

  const fetchedIdsListName = [
    fetchedActual_NFW_Ids,
    fetchedNew_NFW_Ids,
    fetchedCurrent_NFW_Ids,
  ];
  const fetchedListName = [
    fetchedActual_NFW,
    fetchedNew_NFW,
    fetchedCurrent_NFW,
  ];

  const nfListsName = [
    selectedActual_NFW,
    selectedNew_NFW,
    selectedCurrent_NFW,
    selectedFinal_NFW_Ids,
  ];

  const nfFuncName = [
    setSelectedActualNFW,
    setSelectedNewNFW,
    setSelectedCurrentNFW,
  ];

  const handleWardUnchecked = (listIndex) => {
    syncNFLists({ listIndex: listIndex, listsName: nfListsName, fetchedIdsListName, updateFunctions: nfFuncName });
  };

  const handleAccordionOpen = (listIndex) => {
    syncAccordionData({ listIndex: listIndex, listsName: nfListsName, fetchedIdsListName, updateFunctions: nfFuncName });
  };

  // To update final Ward List
  const updateFinalNFW_handler = () => {
    updateFinalNFWList({ listsName: nfListsName, selectedFinal_NFW, fetchedListName, setSelectedFinal_Ids: setSelectedFinalNFW_Ids, setSelectedFinal_Wards: setSelectedFinalNFW })
  };

  useEffect(() => {
    resetAll();
  }, []);

  useEffect(() => {
    if (panel === "actualNFWList") {
      handleWardUnchecked(0);
    }
  }, [selectedActual_NFW]);

  useEffect(() => {
    if (panel === "newNFWList") {
      handleWardUnchecked(1);
    }
  }, [selectedNew_NFW]);

  useEffect(() => {
    if (panel === "currentNFWList") {
      handleWardUnchecked(2);
    }
  }, [selectedCurrent_NFW]);

  useEffect(() => {
    switch (panel) {
      case "actualNFWList":
        handleAccordionOpen(0);
        break;
      case "newNFWList":
        handleAccordionOpen(1);
        break;
      case "currentNFWList":
        handleAccordionOpen(2);
        break;

      default:
        break;
    }
  }, [panel, fetchedNew_NFW]);

  const resetAll = () => {
    setFetchedActualFW([]);
    setFetchedActualNRW([]);
    setFetchedActualNFW([]);
    setFetchedCurrentNFW([]);
    setFetchedCurrentNFW_Ids([]);
    setFetchedNewNFW([]);
    setSelectedActualNFW([]);
    setSelectedCurrentNFW([]);
    setSelectedNewNFW([]);
    setSelectedFinalNFW([]);
    setDeadDevicesTemp([]);
    dispatch(resetSchemes());
  };

  const resetSelection = () => {
    setSelectedActualNFW([]);
    setSelectedCurrentNFW([]);
    setSelectedNewNFW([]);
    setSelectedFinalNFW([]);
  };

  const handleGetReports = async (type) => {
    if (schemeLocation && schemeLocation.district && type === "nonfunctional") {
      getReportsHandler(type, schemeLocation.district, setLoading, { setFetchedActualFW, setFetchedActualNRW, setFetchedActualNFW, setFetchedActualNFW_Ids })
    }
  };
  // const handleGetReports = async (type) => {
  //   if (schemeLocation && schemeLocation.district && type === "nonfunctional") {
  //     try {
  //       setLoading(true);
  //       const reportRes = await getReports({ did: schemeLocation.district, dateRange: get24HoursTimeStamp(1), type, reportReturn: 1 })
  //       setFetchedActualFW(reportRes?.data?.functionalData);
  //       setFetchedActualNRW(reportRes?.data?.nonReporting);
  //       setFetchedActualNFW(reportRes?.data?.nonFunctionalData);
  //       setFetchedActualNFW_Ids(reportRes?.data?.nonFunctionalData?.map((wd) => wd.id));
  //     } catch (error) {
  //       toast.error("Error fetching reports!:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   }
  // };

  const handleGetDeadDevices = async (type) => {
    if (schemeLocation && schemeLocation?.district) {
      try {
        setLoading(true);
        setDeadDevicesTemp([]);
        const deadDeviceRes = await getDeadDevices({ did: schemeLocation?.district, dateRange: historyDate(14, "currentDay") });
        setDeadDevicesTemp(deadDeviceRes?.data);
      } catch (error) {
        toast.error("Error fetching dead devices!:", error);
      } finally {
        setLoading(false);
      }
    }
  };
  // const handleUpdateAvgSchemeStats = async (data) => {
  //   try {
  //     setLoading(true);
  //     const updateRes = await updateAvgSchemeStats(data);
  //     if (updateRes) {
  //       setUpdated(!updated)
  //     }
  //   } catch (error) {
  //     toast.error("Error in updating temp stats data!", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  //TODO: NEEDS TO UPDATE THIS
  const onChangeLocation = (data) => {
    const keys = Object.keys(data);
    let allReset = true;
    for (let i = 0; i < keys?.length; i++) {
      allReset = allReset && !data[keys[i]];
    }

    if (
      data?.block !== schemeLocation?.block &&
      data?.panchayat !== schemeLocation?.panchayat
    ) {
      setFetchedNewNFW([]);
    }

    if (data?.district !== schemeLocation?.district) {
      resetAll();
    }

    if (allReset) {
      resetAll();
    }
    setSchemeLocation(data);
  };

  useEffect(() => {
    if (
      schemeLocation.district ||
      schemeLocation.block ||
      schemeLocation.panchayat ||
      schemeLocation.ward
    ) {
      let d = {};
      if (schemeLocation.district) {
        d["district"] = schemeLocation.district;
      }
      if (schemeLocation.division) {
        d["division"] = schemeLocation.division;
      }
      if (schemeLocation.block) {
        d["block"] = schemeLocation.block;
      }
      if (schemeLocation.panchayat) {
        d["panchayat"] = schemeLocation.panchayat;
      }
      if (schemeLocation.ward) {
        d["ward"] = schemeLocation.ward;
      }
      if (schemeLocation.block) {
        dispatch(getSchemes(d));
      }
    }
  }, [dispatch, schemeLocation]);

  useEffect(() => {
    setFetchedNewNFW(schemes?.data);
    let ids = schemes?.data?.map((s) => s.id);
    setFetchedNewNFW_Ids(ids);
  }, [schemes]);

  const handleOpenAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setPanel(panel);
  };

  const handleTempStatsUpdate = async (wardsData) => {
    try {
      setLoading(true);
      const wDistrict = wardsData?.[0]?.district;
      const wDivisionId =
        wardsData?.[0]?.divisionId || wardsData?.[0]?.division;
      if (wDistrict && wDivisionId) {
        if (
          tempStats?.data
        ) {
          const district = tempStats?.data.district;
          if (district) {
            let smsIds = selectedFinal_NFW?.map((wd) => {
              return wd?.smsid;
            });
            let finalVal = {
              ...tempStats?.data,
              nonFunctionalWards: smsIds,
              calling_ward: tempStats?.data?.calling_ward ? [...tempStats?.data?.calling_ward] : [],
            };

            if (finalVal && "calling_ward" in finalVal) {
              smsIds = removeDuplicate(smsIds, finalVal.calling_ward);
              finalVal.calling_ward.push(...smsIds);
            } else {
              finalVal.calling_ward = smsIds;
            }
            await updateAvgSchemeStatsHandler({ district: district, ...finalVal }, setLoading, updated, setUpdated)
            await generateNFReport({ dept: props?.dept, wardsData: selectedFinal_NFW, });
          } else {
            toast.error("District or division not found or matched!")
          }
        } else {
          toast.error("Temp stats data not found!")
        }
      } else {
        toast.error("District or division not found or matched!")
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // const handleGetCurrentNFW = async (district) => {
  //   if (!district) toast.error("Please select district!")
  //   getCurrentNFWHandler(district, setLoading, { setFetchedCurrentNFW, setFetchedCurrentNFW_Ids })
  // }
  // const handleGetCurrentNFW = async (district) => {
  //   try {
  //     setLoading(true);
  //     setFetchedCurrentNFW([]);
  //     const currentNFWRes = await getCurrentNFW({ did: district });
  //     setFetchedCurrentNFW_Ids(currentNFWRes?.data?.map((item) => item.id))
  //     setFetchedCurrentNFW(currentNFWRes.data);
  //   } catch (error) {
  //     toast.error("Error fetching current non functional reports!:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  // const handleGetOperationalData = async (district) => {
  //   try {
  //     setLoading(true);
  //     const operationalDataRes = await getOperationalData(
  //       { district: district, division: 0 },
  //       historyDate(3, "currentDay"),
  //       props?.dept,
  //       true
  //     );
  //     setTempStats(operationalDataRes.data)
  //   } catch (error) {
  //     toast.error("Error fetching operational data!:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  useEffect(() => {
    if (schemeLocation?.district) {
      getOperationalDataHandler({ district: schemeLocation?.district, setLoading, dept: props?.dept, setTempStats })
      getCurrentNFWHandler(schemeLocation?.district, setLoading, "nonFunctionalWards", { setFetchedCurrentNFW, setFetchedCurrentNFW_Ids })
    }
  }, [schemeLocation?.district, updated]);

  return (
    <>
      {getTitle({ dept: props?.dept + " Reports", end: "Non Functional Reports" })}
      <Box sx={{ p: 0 }}>
        <LocationFilter
          onChange={onChangeLocation}
          initialValues={schemeLocation}
          dept={props?.dept}
        />
        <Box
          sx={{
            paddingTop: 1,
            display: "flex",
            justifyContent: "left",
            columnGap: "10px",
          }}
        >
          <Button
            disabled={!schemeLocation?.district || loading}
            variant="outlined"
            size="small"
            onClick={() => handleGetDeadDevices()}
          >
            GET DEAD DEVICES
          </Button>
          <Button
            disabled={!schemeLocation?.district || loading}
            variant="outlined"
            size="small"
            onClick={() => handleGetReports("nonfunctional")}
          >
            Get Actual Report
          </Button>
          <Button
            // disabled={(!selectedFinal_NFW && !selectedNew_NFW && !selectedCurrent_NFW) || loading }
            variant="outlined"
            size="small"
            onClick={() => updateFinalNFW_handler()}
          >
            Update list
          </Button>
          <ShowWhen
            when={selectedFinal_NFW?.length}
            component={
              <Box sx={{ display: "flex", columnGap: "10px" }}>
                <PDFDownloadLink
                  document={
                    <MyDocument
                      data={selectedFinal_NFW}
                      district={capitalizedWordsFirstLetterWithSpace(
                        selectedFinal_NFW[0]?.districtName || " "
                      )}
                      titleForPdf={capitalizedWordsFirstLetterWithSpace(
                        props?.titleForPdf
                      )}
                    />
                  }
                  fileName={generateCapitalizedFilename({
                    name: selectedFinal_NFW[0]?.districtName,
                  })}
                >
                  {({ blob, url, loading, error }) => (
                    <ShowWhen
                      when={!loading}
                      component={
                        <>
                          <Button
                            disabled={loading}
                            variant="outlined"
                            size="small"
                          >
                            {`download Report (${selectedFinal_NFW?.length || 0
                              })`}
                          </Button>
                        </>
                      }
                      otherwise={
                        <>
                          <Button
                            disabled={loading}
                            variant="outlined"
                            size="small"
                          >
                            loading...
                          </Button>
                        </>
                      }
                    />
                  )}
                </PDFDownloadLink>
                <Button
                  type="button"
                  onClick={() => handleTempStatsUpdate(selectedFinal_NFW)}
                  disabled={loading}
                  variant="outlined"
                  size="small"
                >
                  Update Final Report
                </Button>
                <Button
                  type="button"
                  onClick={() => resetSelection()}
                  disabled={loading}
                  variant="outlined"
                  size="small"
                >
                  Reset Report
                </Button>
              </Box>
            }
          />
        </Box>

        <Grid mt={3} direction={"column"} marginBottom={"10px"}>
          <Grid item xs={12}>
            <Paper
              elevation={0}
              variant="outlined"
              style={{ padding: "10px", marginBottom: "5px" }}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography gutterBottom>
                Installed :{" "}
                <span style={{ color: priColor }}>
                  {tempStats?.data?.functional +
                    tempStats?.data?.nonFunctional +
                    tempStats?.data?.nonReporting || 0}
                </span>
              </Typography>
              <Typography gutterBottom>
                Count of functional :{" "}
                <span style={{ color: priColor }}>
                  {tempStats?.data?.functional || 0}
                </span>
              </Typography>
              <Typography gutterBottom>
                Count of non-functional :{" "}
                <span style={{ color: priColor }}>
                  {tempStats?.data?.nonFunctional || 0}
                </span>
              </Typography>
              <Typography gutterBottom>
                Count of non-reporting :{" "}
                <span style={{ color: priColor }}>
                  {tempStats?.data?.nonReporting || 0}
                </span>
              </Typography>
            </Paper>
          </Grid>
          <Grid item xl={12} md={12} xs={12}>
            <CustomAccordion
              expanded={expanded}
              expandedItem={"newNFWList"}
              title={`Select Wards to add in non-functional report (${fetchedNew_NFW?.length || 0
                })`}
              id="panel1bh-header"
              onChange={handleOpenAccordion("newNFWList")}
            >
              <WardList
                listType="nf"
                title=" "
                loading={loading}
                checkboxSelection={true}
                selectionModel={selectedNew_NFW}
                setSelectionModel={setSelectedNewNFW}
                rowSelectable={true}
                sx={{ height: "100%" }}
                schemes={fetchedNew_NFW}
                showExport={true}
                tempStats={tempStats?.data}
                schemeLocation={schemeLocation}
                distName={"_Select_Wards_"}
              />
            </CustomAccordion>
          </Grid>
        </Grid>

        <Grid direction={"column"} marginBottom={"10px"}>
          <Grid item xl={12} md={12} xs={12}>
            <CustomAccordion
              expanded={expanded}
              expandedItem={"currentNFWList"}
              title={`Current non-functional reports (${fetchedCurrent_NFW?.length || 0
                })`}
              id="panel1bh-header"
              onChange={handleOpenAccordion("currentNFWList")}
            >
              <WardList
                listType="nf"
                title=" "
                loading={loading}
                checkboxSelection={true}
                selectionModel={selectedCurrent_NFW}
                setSelectionModel={setSelectedCurrentNFW}
                rowSelectable={true}
                sx={{ height: "100%" }}
                schemes={fetchedCurrent_NFW}
                showExport={true}
                tempStats={tempStats?.data}
                schemeLocation={schemeLocation}
                distName={"_current_non_functional_report_"}
              />
            </CustomAccordion>
          </Grid>
        </Grid>

        <Box>
          <Grid direction={"column"} marginBottom={"10px"}>
            <Grid item xl={12} md={12} xs={12}>
              <CustomAccordion
                expanded={expanded}
                expandedItem={"actualNFWList"}
                title={`Non-Functional Report (${fetchedActual_NFW?.length || 0
                  })`}
                id="panel1bh-header"
                onChange={handleOpenAccordion("actualNFWList")}
              >
                <WardList
                  listType="nf"
                  title=" "
                  loading={ward?.status === "loading"}
                  checkboxSelection={true}
                  sx={{ height: "100%" }}
                  schemes={fetchedActual_NFW}
                  fileName={fetchedActual_NFW?.[0]?.districtName || ""}
                  distName={"_non_func_report"}
                  showExport={true}
                  tempStats={tempStats?.data}
                  selectionModel={selectedActual_NFW}
                  setSelectionModel={setSelectedActualNFW}
                />
              </CustomAccordion>
            </Grid>
          </Grid>

          <Grid direction={"column"} marginBottom={"10px"}>
            <Grid item xl={12} md={12} xs={12}>
              <CustomAccordion
                expanded={expanded}
                expandedItem={"panel0"}
                title={`Non-Reporting Reports (${fetchedActual_NRW?.length || 0
                  })`}
                id="panel1bh-header"
                onChange={handleOpenAccordion("panel0")}
              >
                <WardList
                  title=" "
                  loading={loading}
                  sx={{ height: "100%" }}
                  schemes={fetchedActual_NRW}
                  showExport={true}
                  fileName={fetchedActual_NFW?.[0]?.districtName || ""}
                  distName={"_non_reporting_reports"}
                />
              </CustomAccordion>
            </Grid>
          </Grid>

          <Grid direction={"column"} marginBottom={"10px"}>
            <Grid item xl={12} md={12} xs={12}>
              <CustomAccordion
                expanded={expanded}
                expandedItem={"panel4"}
                title={`Functional ward Reports (${fetchedActual_FW?.length || 0
                  })`}
                id="panel1bh-header"
                onChange={handleOpenAccordion("panel4")}
              >
                <WardList
                  title="  "
                  loading={loading}
                  sx={{ height: "100%" }}
                  schemes={fetchedActual_FW}
                  showExport={true}
                  fileName={fetchedActual_NFW?.[0]?.districtName || ""}
                  distName={"_functional_report"}
                />
              </CustomAccordion>
            </Grid>
          </Grid>

          <Grid direction={"column"} marginBottom={"10px"}>
            <Grid item xl={12} md={12} xs={12}>
              <CustomAccordion
                expanded={expanded}
                expandedItem={"panel5"}
                title={`Dead Devices (${deadDevicesTemp?.length || 0})`}
                id="panel1bh-header"
                onChange={handleOpenAccordion("panel5")}
              >
                <DeadWardList
                  title=" "
                  loading={loading}
                  sx={{ height: "100%" }}
                  schemes={deadDevicesTemp}
                  showExport={true}
                  fileName={deadDevicesTemp?.[0]?.districtName || ""}
                  distName={"_dead_devices_"}
                />
              </CustomAccordion>
            </Grid>
          </Grid>
        </Box>
        <ShowWhen
          when={loading}
          component={<Loader />}
        />
      </Box>
    </>
  );
}
