import getTitle from "../../../components/common/Title"
import Placeholder from "../../../components/common/placeholder"

const UserManagement = () => {
    return (
        <>
            {
                getTitle({ dept: "User Management", end: "Har Ghar Nal Jal Yojna" })
            }
            <Placeholder title="Users management features available soon!" />
        </>
    )
}

export default UserManagement