import { Box, Button, Divider, Grid, Paper, Switch, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { getWard, resetSchemes } from "../../slices/siteInfo";
import { manualDeviceTrigger } from "../../slices/manual_trigger";
import LocationFilter from "../LocationFilter";
import { LoaderIcon } from "react-hot-toast";
import ShowWhen from "../ShowWhen";
import Loader from "../Loader/Loader";
import { getLastPowerOn, resetLastPowerOn } from "../../slices/lastPowerOn";
import { getDiffInMin, } from "../../utils/helper";
import getTitle from "./Title";

function TriggerDeviceEvent({ dept = '' }) {
    const dispatch = useDispatch();
    const [schemeLocation, setSchemeLocation] = useState({});
    const { ward } = useSelector((state) => state.siteInfo);
    const { manualDeviceTrigger: triggerData } = useSelector((state) => state.manualTrigger);
    const { lastPowerOn } = useSelector((state) => state.lastPowerOn)
    const [allSchemes, setAllSchemes] = useState([]);
    const [saveTriggered, setSaveTriggered] = useState(false)
    const [tableData, setTableData] = useState([])

    const onChangeLocation = (data) => {
        const keys = Object.keys(data);
        let allReset = true;
        for (let i = 0; i < keys.length; i++) {
            allReset = allReset && !data[keys[i]];
        }

        if (
            data?.block !== schemeLocation?.block &&
            data?.panchayat !== schemeLocation?.panchayat
        ) {
            setAllSchemes([]);
            setTableData([])
        }

        if (data?.district !== schemeLocation?.district) {
            setAllSchemes([]);
            setTableData([])
        }

        if (allReset) {
            setAllSchemes([]);
            setTableData([])
            dispatch(resetSchemes());
        }
        setSchemeLocation(data);
        setSaveTriggered(false)
    };

    const fetchDeviceStatus = (type) => {
        const schemesData = ward?.data
        let deviceIdsTemp = []
        if (schemesData && schemesData?.length) {
            schemesData.map((scm) => {
                if (scm?.temp_smsid) {
                    deviceIdsTemp.push(scm.temp_smsid);
                } else {
                    deviceIdsTemp.push(scm.smsid);
                }
            })
            let smsIds = new Set(deviceIdsTemp)
            smsIds = Array.from(smsIds)
            const wSmsIds = smsIds.filter(e => e)
            dispatch(getLastPowerOn(wSmsIds, type))
        }
    }


    useEffect(() => {
        if (ward && ward.status === 'SUCCESS') {
            setAllSchemes(ward.data)
            if (ward && ward?.data) {
                fetchDeviceStatus("Initial")
            }
        }
    }, [ward])


    const handleChange = (type, data, checked, clicked = false) => {
        let allSchemesTemp = [...tableData]
        setSaveTriggered(false)

        for (let i = 0; i < allSchemesTemp.length; i++) {
            if (allSchemesTemp[i]['id'] === data['id']) {
                let triggerData = {}
                if (allSchemesTemp[i]['manually_trigger']) {
                    triggerData = { ...allSchemesTemp[i]['manually_trigger'] }
                }

                triggerData = { ...triggerData, [type]: checked }

                if (checked) {
                    if (type === 'motor') {
                        triggerData['electricity'] = true
                    }
                } else {
                    if (type === 'electricity') {
                        triggerData['motor'] = false
                    }
                }

                allSchemesTemp[i] = {
                    ...allSchemesTemp[i],
                    clicked: clicked,
                    manually_trigger: {
                        ...triggerData
                    }
                }
            }
        }
        setTableData(allSchemesTemp)
    };

    const updateManualTrigger = () => {
        const dt = new Date();
        let tableDataCopy = tableData.filter((e) => e.clicked === true);
        let updateData = tableDataCopy.map((e) => {
            if (e.manually_trigger)
                return {
                    id: e.smsid,
                    current: e.manually_trigger.motor ? 3.0 : e.manually_trigger.electricity ? 1.2 : 0,
                    smsid: e.smsid,
                    deviceId: e.smsid,
                    didMonth: `${dt.getFullYear()}-${dt.getMonth()}-${e.smsid}`
                }
        })


        if (updateData.length > 0) {
            dispatch(manualDeviceTrigger(updateData))
        }
        setSaveTriggered(true);
    }

    useEffect(() => {
        dispatch(resetLastPowerOn())
    }, [])


    useEffect(() => {
        let wardData = ward?.data
        if (wardData && wardData.length) {
            let tableDataTemp = []
            let lastPowerOnData = []
            if (lastPowerOn["data"] && lastPowerOn["data"].length) {
                lastPowerOnData = lastPowerOn["data"]
            }
            wardData.map((page, i) => {
                let motorOn = false
                let powerOn = false
                let lpo = null
                let network = false
                if (lastPowerOnData) {
                    let lastDataReceivedTsArr = []
                    // lastDataReceivedTsArr = lastPowerOnData.filter((e) => e.id === page.smsid)
                    if (page.temp_smsid) {
                        lastDataReceivedTsArr = lastPowerOnData.filter((e) => e.id === page.temp_smsid);
                    } else {
                        lastDataReceivedTsArr = lastPowerOnData.filter((e) => e.id === page.smsid);
                    }
                    if (lastDataReceivedTsArr && lastDataReceivedTsArr.length) {
                        let ldr = lastDataReceivedTsArr[0]
                        let lastDataReceivedTs = ldr["timestamp"] ? Number(ldr["timestamp"]) : ldr["_ts"]
                        lpo = lastDataReceivedTs
                        let t1 = new Date()
                        let diff = getDiffInMin(lastDataReceivedTs, t1.getTime() / 1000)
                        if (ldr.current >= 2) {
                            if (diff <= 17) {
                                motorOn = true
                            }
                        }

                        if (diff <= 17) {
                            powerOn = true
                        }

                        if (diff <= 4320) {
                            network = true
                        }
                    }
                }

                let manually_trigger = {
                    motor: false,
                    electricity: false,
                }

                if (powerOn) {
                    manually_trigger.electricity = true
                }

                if (motorOn) {
                    manually_trigger.motor = true
                    manually_trigger.electricity = true
                }

                tableDataTemp.push({
                    lpo,
                    ...page,
                    powerOn,
                    motorOn,
                    network,
                    manually_trigger
                })
            })

            tableDataTemp.sort((a, b) => b.lpo - a.lpo)
            setTableData(tableDataTemp)
        }
    }, [lastPowerOn])

    const handleRefresh = () => {
        if (schemeLocation?.panchayat) {
            dispatch(getWard(schemeLocation?.panchayat, dept));
        }
        // fetchDeviceStatus("Initial")
    }


    return <>
        {getTitle({ dept: dept, end: "Trigger Device Event" })}
        <Box >
            <LocationFilter
                onChange={onChangeLocation}
                initialValues={schemeLocation}
                schemes={allSchemes}
                dept={dept || null}
            />
            <Box mt={3}>
                <Paper>
                    {
                        tableData && tableData?.length > 0 && (
                            <>
                                <Grid width={"100%"} justifyContent={"flex-end"} alignItems="center">
                                    <Button type="button" disabled={lastPowerOn?.status === "loading"} variant="outlined" onClick={() => handleRefresh()}>
                                        refresh
                                    </Button>
                                </Grid>
                                <Divider />
                            </>
                        )
                    }

                    <ShowWhen when={lastPowerOn?.status === "loading"} component={<Typography variant="h4">Loading...</Typography>} otherwise={
                        <>
                            <Grid container direction={'column'}>
                                {tableData && tableData.map((wd, key) => {
                                    return (
                                        <Grid item key={key} container display={'flex'} gap={2} borderBottom={'1px solid #CCC'} alignItems={'center'}>
                                            <Grid item md={2} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                Ward {wd.name} {wd?.temp_smsid ? <Typography color={"#ff841d"} variant="body2">: (M) </Typography> : ""}
                                            </Grid>
                                            <Grid item md={2}  >
                                                <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'}>
                                                    <Grid item>
                                                        <Typography fontWeight={'bold'}>Electricity</Typography>
                                                    </Grid>
                                                    <Grid item md={4}>
                                                        Off <Switch
                                                            checked={(wd["manually_trigger"] && wd["manually_trigger"]["electricity"] ? wd["manually_trigger"]["electricity"] : false)}
                                                            onChange={(event) => handleChange('electricity', wd, event.target.checked, true)}
                                                        /> On
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item md={2}>
                                                <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'}>
                                                    <Grid item>
                                                        <Typography fontWeight={'bold'}>Motor</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        Off <Switch
                                                            checked={wd["manually_trigger"] && wd["manually_trigger"]["motor"] ? wd["manually_trigger"]["motor"] : false}
                                                            onChange={(event) => handleChange('motor', wd, event.target.checked, true)}
                                                        /> On
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                            {tableData.length ?
                                <Box sx={{
                                    padding: 3,
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>
                                    <Button disabled={saveTriggered || triggerData?.status === "loading"} variant="contained" onClick={updateManualTrigger}>
                                        <ShowWhen when={triggerData?.status === "loading"} component={<LoaderIcon />} otherwise={"Save"} />
                                    </Button>
                                </Box> : null}
                        </>
                    } />
                </Paper>
            </Box>
            <ShowWhen when={ward && ward.status === 'loading'} component={<Loader />} />
        </Box>
    </>
}

export default TriggerDeviceEvent