import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { checkIfRoleExist } from '../../utils/helper';
import useAuth from '../../hooks/useAuth';

const AppAdminGuard = ({ children }) => {
    const user = useAuth();
    const userType = localStorage.getItem("USER_TYPE");
    const dept = user?.user?.dept?.toUpperCase();

    if (!userType || !checkIfRoleExist(userType, ["APP_SUPER_ADMIN", "APP_ADMIN"])) {
        return <Navigate to="/authentication/login" />;
    }

    if (userType === "APP_SUPER_ADMIN") {
        return <>{children}</>;
    }

    if (userType.includes("APP_ADMIN")) {
        if (["PHED", "UDHD", "PRD"].some(role => dept.includes(role))) {
            return <>{children}</>;
        }
        return <Navigate to="/dashboard" />;
    }

    return <Navigate to="/dashboard" />;
};

AppAdminGuard.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AppAdminGuard;
