import { createSlice } from "@reduxjs/toolkit";
import { filePost } from "./service";
import { API_BASE_URL } from "../config";
import toast from "react-hot-toast";

const initialState = {
    reportUpload: {
        status: null
    }
};

const slice = createSlice({
    name: 'uploadReports',
    initialState,
    reducers: {
        setReportUpload(state, action) {
            state.reportUpload = action.payload
        },
        resetStatus: (state, action) => {
            state.reportUpload = {
                data: [], status: null
            }
        },
    }
});

export const { reducer } = slice;

export const uploadReportsPdfORExcel = ({ dept = '', file, divisionName = null, districtId = null }) => async (dispatch) => {
    if (!file || !divisionName || !dept || !districtId) {
        toast.error('File, district id, division name and dept are required!');
        return;
    }

    dispatch(slice.actions.setReportUpload({ ...initialState.reportUpload, status: "loading" }));
    filePost(API_BASE_URL + "phed/maintenance/uploadReportAndGetURL", { uifile: file, districtId, divisionName, dept })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setReportUpload(response));
            toast.success(response?.message ? response?.message : "Report file uploaded successfully!");
        })
        .catch(function (errResponse) {
            toast(errResponse?.message ? errResponse?.message : "Something went wrong in uploading report!", { type: "error" });
            dispatch(slice.actions.setReportUpload({ ...errResponse, status: "failed" }))
        });
};


export default slice;