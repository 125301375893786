const { BASE_URL } = require("../config");

const getAccessToken = () => localStorage.getItem("accessToken");

const handleUnauthorized = (response) => {
    if (response?.status === 401 || response?.statusText?.toUpperCase() === "UNAUTHORIZED") {
        localStorage.clear();
        window.location.href = BASE_URL;
        return null;
    }
    return response.json();
};

const request = async (url, params = {}, method = 'GET', type = null) => {
    const accessToken = getAccessToken();

    const options = {
        method,
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${accessToken}`,
        },
    };

    method = method.toUpperCase();
    type = type?.toUpperCase();

    if (method === 'GET') {
        url += '?' + new URLSearchParams(params).toString();
    } else if (type === "FILE") {
        delete options.headers["Content-Type"];
        options.headers.redirect = 'follow';
        const formData = new FormData();
        Object.keys(params).forEach(key => formData.append(key, params[key]));
        options.body = formData;
    } else {
        options.body = JSON.stringify(params);
    }

    try {
        const response = await fetch(url, options);
        return handleUnauthorized(response);
    } catch (error) {
        console.error('API request error:', error);
        throw error;
    }
};

export const get = (url, params) => request(url, params, 'GET');
export const post = (url, params) => request(url, params, 'POST');
export const filePost = (url, data) => request(url, data, 'POST', "FILE");
export const put = (url, params) => request(url, params, 'PUT');
export const del = (url, params) => request(url, params, 'DELETE');

