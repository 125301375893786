import toast from "react-hot-toast";
import { API_BASE_URL } from "../config";
import { get, post } from "../slices/service";

export const getOperationalData = async (
    location,
    dateRange,
    dept = "",
    isOnlyTempData = false,
) => {
    try {
        if (!location || !dateRange) {
            throw new Error("Location or date range is missing");
        }
        const response = await post(API_BASE_URL + "phed/device_operational_status", {
            ...location,
            dateRange,
            dept,
            isOnlyTempData,
        });

        if (response.statusCode === 200) {
            return response;
        } else {
            throw new Error(response.message || "Error in fetching operational data!");
        }
    } catch (error) {
        toast.error(error.message || "Error in fetching operational data!", {
            duration: 4000,
        });
    }
};



export const getAvgSchemeStats = async ({
    did,
    divid,
    dateRange,
    isOnlyTempData = false,
}) => {
    try {
        if (!did || !dateRange) {
            return
        }
        const response = await get(API_BASE_URL + "phed/homepage_device_stats", {
            did,
            divid,
            from: dateRange[0],
            to: dateRange[1],
            isOnlyTempData,
        });

        if (response.statusCode === 200) {
            return response;
        } else {
            throw new Error(response.message || "Error in fetching average scheme stats!");
        }
    } catch (error) {
        toast.error(error.message || "Error in fetching average scheme stats!", {
            duration: 4000,
        });
    }
};




export const updateAvgSchemeStats = async (data) => {
    try {
        if (!data) {
            throw new Error("Data is missing");
        }
        const response = await post(API_BASE_URL + "phed/update-temp-stats", data);

        if (response.statusCode === 200) {
            toast.success(response.message || "Updated Successfully!", {
                duration: 4000,
            });
            return response;
        } else {
            throw new Error(response.message || "Error in updating temp stats data!");
        }
    } catch (error) {
        toast.error(error.message || "Error in updating temp stats data!", {
            duration: 4000,
        });
    }
};