import { Document, Page, Text, View, StyleSheet, Image, Font, } from "@react-pdf/renderer";
import Logo from "../images/har_ghar_nal_jal_yojna.png";
import calibriRegularFont from "../fonts/calibri-font-family/calibri-bold.ttf";



Font.register({
  family: "calibri",
  fonts: [
    // { src: calibriBoldFont },
    // { src: calibriBoldItalicFont },
    // { src: calibriItalicFont },
    { src: calibriRegularFont },
  ],
});

export const styles = StyleSheet.create({
  base: {
    fontSize: "12px",
  },
  mt0: {
    marginTop: "50px",
  },
  headingColor: {
    color: "#158366",
    fontSize: "11px",
    fontWeight: "bold",
    fontFamily: "calibri",
  },
  heading: {
    fontSize: "11px",
    fontWeight: "bold",
    fontFamily: "calibri",
  },
  table: {
    alignSelf: "center",
    display: "table",
    width: "85%",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  fullWidth: {
    width: "100%",
    margin: "0 auto",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "25.66%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  snlWidth: {
    width: "8%",
  },
  wardWidth: {
    width: "15%",
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    marginBottom: 5,
    fontSize: 9,
  },
  image: {
    height: "50px",
    width: "200px",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

const MyDocument = ({ data, district, titleForPdf, reportTitle = "Non-Functional Report" }) => {

  const pdfHeadings = [
    {
      heading: "Jal Monitoring System",
    },
    {
      heading: "Public Health Department",
    },
    {
      heading: reportTitle,
    },
  ];

  return (
    <Document>
      <Page size="A4">
        <View style={[styles.table, styles.base, styles.mt0]}>
          <View style={[styles.tableRow]}>
            <View style={[styles.tableCol, styles.fullWidth]}>
              <Text style={[styles.tableCell]}>
                <Image src={Logo} style={styles.image} />
              </Text>
            </View>
          </View>
          {pdfHeadings?.map((ph) => (
            <View key={ph} style={[styles.tableRow]}>
              <View style={[styles.tableCol, styles.fullWidth]}>
                <Text style={[styles.tableCell, styles.headingColor]}>
                  {ph?.heading !== "Public Health Department"
                    ? ph?.heading
                    : `${district} ${titleForPdf}`}
                </Text>
              </View>
            </View>
          ))}
          <View style={[styles.tableRow]}>
            <View style={[styles.tableCol, styles.fullWidth]}>
              <Text style={[styles.tableCell, styles.headingColor]}>&nbsp;</Text>
            </View>
          </View>
          <View style={[styles.tableRow]}>
            <View style={[styles.tableCol, styles.snlWidth]}>
              <Text style={[styles.tableCell, styles.heading]}>S.No.</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={[styles.tableCell, styles.heading]}>DivisionName</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={[styles.tableCell, styles.heading]}>BlockName</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={[styles.tableCell, styles.heading]}>
                PanchayatName
              </Text>
            </View>
            <View style={[styles.tableCol, styles.wardWidth]}>
              <Text style={[styles.tableCell, styles.heading]}>Ward No</Text>
            </View>
          </View>
          {data?.map((wd, i) => (
            <View key={i} style={styles.tableRow}>
              <View style={[styles.tableCol, styles.snlWidth]}>
                <Text style={styles.tableCell}>{i + 1}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {wd?.districtName?.toUpperCase()}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {wd?.blockName?.toUpperCase()}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {wd?.panchayatName?.toUpperCase()}
                </Text>
              </View>
              <View style={[styles.tableCol, styles.wardWidth]}>
                <Text style={styles.tableCell}>{wd?.name?.toUpperCase()}</Text>
              </View>
              {/* <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{wd?.lastOperatedOn}</Text>
              </View> */}
            </View>
          ))}
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  )
}


export default MyDocument