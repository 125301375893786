import CommonButtonGroup from "./CommonButtonGroup";
import NonFunctionalReports from "./NonFunctionalReports";
import NonReportingReports from "./NonReportingReports";

const menus = [
    "Non-functional report",
    "Non-reporting report",
];

export default function ReportsButtonGroup({ dept, titleForPdf }) {
    const renderContent = (menuId) => {
        switch (menuId) {
            case 0: return <NonFunctionalReports dept={dept} titleForPdf={titleForPdf} />;
            case 1: return <NonReportingReports dept={dept} titleForPdf={titleForPdf} />;
            default: return null;
        }
    };

    return (
        <CommonButtonGroup menus={menus} renderContent={renderContent} />
    );
}

