import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { getDateTime, isSmsIdInBothArrays, } from "../utils/helper";
import { Card } from "@mui/material";
import CustomNoRowsOverlay from "./common/CustomNoRowsOverlay";
import BadgePills from "./BadgePills";
import CustomToolbar from "./common/CustomToolbar";


const WardList = (props) => {
  const [tableData, setTableData] = useState([]);

  const twoDayNFWardsFormatter = (cell) => {
    return (
      <BadgePills status={cell} color={"red"} padding="8px" />
    );
  }


  useEffect(() => {
    let tableDataTemp = [];
    if (props.schemes && props.schemes.length) {
      props.schemes.map((page, i) => {

        const lastOperatedOn = page?.lastOperatedOn
          ? getDateTime(page?.lastOperatedOn)
          : "-";

        tableDataTemp.push({
          ...page,
          ...page?.other,
          did: page?.other?.deviceid,
          wname: page?.name,
          lastOperatedOn: `${lastOperatedOn?.date ? lastOperatedOn?.date : "-"
            } ${lastOperatedOn?.time ? lastOperatedOn?.time : "-"}`,
          id: page?.id,
          slno: i + 1,
          nfWardStatus: isSmsIdInBothArrays({ smsid: page?.smsid, nonFunctionalWards: props?.tempStats?.nonFunctionalWards, twoDayNFWards: props?.tempStats?.twoDayNFWards }) ? "Already included!" : ''
        });
      });
    }

    setTableData(tableDataTemp);
  }, [props.schemes, props.showWardsIds]);

  const columns = [
    {
      field: "slno",
      headerName: "Sl No",
      width: 80,
      sortable: true,
      headerClassName: "scheme-data-table",
    },
    {
      field: "id",
      hide: true,
      width: 130,
      sortable: true,
      headerClassName: "scheme-data-table",
    },
    {
      field: "districtName",
      headerName: "Distric",
      width: 130,
      sortable: false,
    },
    {
      field: "blockName",
      headerName: "Block",
      width: 130,
      sortable: true,
    },
    {
      field: "panchayatName",
      headerName: "Panchayat",
      width: 130,
      sortable: true,
    },
    {
      field: "wname",
      headerName: "Ward",
      width: 130,
      sortable: false,
    },
  ];

  if (props?.listType?.toUpperCase() === "NF") {
    columns.push({
      field: "nfWardStatus",
      headerName: "NFW 2-Day Status",
      width: 200,
      sortable: false,
      renderCell: (params) => {
        return twoDayNFWardsFormatter(params.value);
      },
    })
  }


  const handleSelectionChange = (values) => {
    props?.setSelectionModel(values)
  }

  return (
    <Card>
      <div
        style={{ height: 683, width: "100%" }}
      >
        <DataGrid
          loading={props?.loading || false}
          checkboxSelection={props?.checkboxSelection ? props?.checkboxSelection : false}
          onSelectionModelChange={handleSelectionChange}
          selectionModel={props?.selectionModel}
          rows={tableData}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[5]}
          components={{
            Toolbar: CustomToolbar,
          }}
          isRowSelectable={(params) =>
            props?.rowSelectable
              ? !props?.rowSelectableIds?.includes(params?.row?.smsid)
              : true
          }
          componentsProps={{
            toolbar: {
              fileName: props.fileName,
              locationName: props.distName,
              title: props?.title,
              showExport: props?.showExport,
            },
          }}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          localeText={{ noRowsLabel: "No Records  Found :)" }}
        />
      </div>
    </Card>
  );
};

export default WardList;
