import {
  Breadcrumbs,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Container, Link } from "@mui/material";
import ChevronRight from "../../icons/ChevronRight";
import { Link as RouterLink } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import getTitle from "../../components/common/Title";

function Profile(props) {
  return (
    <>
      {
        getTitle({ end: "Update Profile" })
      }
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Container maxWidth={"xl"}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Update Profile
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRight fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard/phed"
                  variant="subtitle2"
                >
                  Home
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Profile
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>

          <Container maxWidth={"sm"}>
            <Box sx={{ py: 3 }}>
              <Formik
                initialValues={{
                  name: "",
                  mobile: "",
                  email: "",
                  submit: null,
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string()
                    .min(5)
                    .max(12)
                    .required("Name is required!"),
                  mobile: Yup.string()
                    .min(5)
                    .max(12)
                    .required("Mobile is required!"),
                  email: Yup.string()
                    .min(5)
                    .max(12)
                    .required("Email is required!"),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  try {
                    // await login(values.email, values.password);
                    // if (mounted.current) {
                    //   setStatus({ success: true });
                    //   setSubmitting(false);
                    // }
                  } catch (err) {
                    console.error(err);
                    // if (mounted.current) {
                    //   setStatus({ success: false });
                    //   setErrors({ submit: "Username and Password did not match" });
                    //   setSubmitting(false);
                    // }
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit} {...props}>
                    <TextField
                      autoFocus
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label="Name"
                      margin="normal"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.mobile && errors.mobile)}
                      fullWidth
                      helperText={touched.mobile && errors.mobile}
                      label="Mobile"
                      margin="normal"
                      name="mobile"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.mobile}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(
                        touched.email && errors.email
                      )}
                      fullWidth
                      helperText={
                        touched.email && errors.email
                      }
                      label="Email"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      type="email"
                      onChange={handleChange}
                      value={values.password}
                      variant="outlined"
                    />
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        color="secondary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Update
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Container>
        </Container>
      </Box>
    </>
  );
}

export default Profile;
