export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE",
};

export const title = {
  label1: "Bihar Government",
  label2: "Har Ghar Nal Jal Yojna",
};

export const colors = {
  textGreen: "#10b759",
  textYellow: "#fbbc06",
};

export const priColor = "#727cf5";

export const operationalInitialValue = {
  nonFunctional: 0,
  functional: 0,
  functionalWLS: 0,
  functionalPWS: 0,
  nonFunctionalWLS: 0,
  nonFunctionalPWS: 0,
};

export const DASHBOARD_URL = [
  {
    url: "https://phed.rollzdashboard.com",
    name: "Public Health Engineering Department",
    enable: true,
  },
  {
    url: "https://prd.rollzdashboard.com",
    name: "Panchayati Raj Department",
    enable: true,
  },
  {
    url: "https://udhd.rollzdashboard.com",
    name: "Urban Development and Housing Department",
    enable: true,
  },
];