import React, { useEffect, useState } from "react";
import { Button, Grid, MenuItem } from "@mui/material";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getBlock,
  getDistrict,
  getDivision,
  getPanchayat,
  getWard,
  resetLocations,
  resetSchemes,
} from "../slices/siteInfo";
import _ from "underscore";
import { resetOpData } from "../slices/schemesDeviceOperational";
import { resetDeviceStats } from "../slices/deviceStats";
import ShowWhen from "./ShowWhen";
import Loader from "./Loader/Loader";
import { resetCurrentNFW } from "../slices/downloadReport";

const LocationFilter = (props) => {
  const dispatch = useDispatch();
  const { district, division, block, panchayat, ward } = useSelector(
    (state) => state.siteInfo
  );
  const [districtData, setDistrictData] = useState([]);
  const [divisionData, setDivisionData] = useState([]);
  const [blockData, setBlockData] = useState([]);
  const [panchayatData, setPanchayatData] = useState([]);
  const [wardData, setWardData] = useState([]);

  let distInit = { id: "", name: "" };
  if (props.initialValues) {
    if (props.initialValues["district"]) {
      distInit = { id: props.initialValues["district"], name: "" };
    }
  }

  let divInit = { id: "", name: "" };
  if (props.initialValues) {
    if (props.initialValues["division"]) {
      divInit = { id: props.initialValues["division"], name: "" };
    }
  }

  let blockInit = { id: "", name: "" };
  if (props.initialValues) {
    if (props.initialValues["block"]) {
      blockInit = { id: props.initialValues["block"], name: "" };
    }
  }

  let panchayatInit = { id: "", name: "" };
  if (props.initialValues) {
    if (props.initialValues["panchayat"]) {
      panchayatInit = { id: props.initialValues["panchayat"], name: "" };
    }
  }

  let wardInit = { id: "", name: "" };
  if (props.initialValues) {
    if (props.initialValues["ward"]) {
      wardInit = { id: props.initialValues["ward"], name: "" };
    }
  }
  const [selectedDistrict, setSelectedDistrict] = useState(distInit);
  const [selectedDivision, setSelectedDivision] = useState(divInit);
  const [selectedBlock, setSelectedBlock] = useState(blockInit);
  const [selectedPanchayat, setSelectedPanchayat] = useState(panchayatInit);
  const [selectedWard, setSelectedWard] = useState(wardInit);

  useEffect(() => {
    reset(true);
    dispatch(getDistrict({ dept: props?.dept }));
  }, []);

  useEffect(() => {
    let distData = district.data || [];
    setDistrictData(distData);
    // dispatch(getDivision(distInit["id"]));
  }, [district]);

  useEffect(() => {
    if (selectedDistrict?.id) {
      setDivisionData(division.data || []);
      // dispatch(getBlock(selectedDivision?.id));
    }
  }, [division]);

  useEffect(() => {
    setBlockData(block.data || []);
  }, [block]);

  useEffect(() => {
    setPanchayatData(panchayat.data || []);
  }, [panchayat]);

  useEffect(() => {
    setWardData(ward.data || []);
  }, [ward]);

  const reset = (all = false) => {
    // localStorage.setItem("locationData",
    //     JSON.stringify({
    //         block: "",
    //         division: "",
    //         district: "",
    //         panchayat: "",
    //         ward: ""
    //     }))
    // dispatch(setFilters({
    //     block: "",
    //     division: "",
    //     district: "",
    //     panchayat: "",
    //     ward: ""
    // }))
    setSelectedDistrict({});
    setSelectedDivision({});
    setSelectedBlock({});
    setSelectedPanchayat({});
    setSelectedWard({});
    dispatch(resetOpData());
    dispatch(resetDeviceStats());
    dispatch(resetSchemes());
    if (all) {
      dispatch(resetCurrentNFW());
      dispatch(resetLocations("district"));
      dispatch(resetLocations("block"));
      dispatch(resetLocations("panchayat"));
      dispatch(resetLocations("ward"));
    }
  };

  const resetOther = (cfield) => {
    if (cfield == "dist") {
      setSelectedDivision({});
      setSelectedBlock({});
      setSelectedPanchayat({});
      setSelectedWard({});
      dispatch(resetLocations("block"));
      dispatch(resetLocations("panchayat"));
      dispatch(resetLocations("ward"));
    }

    if (cfield == "div") {
      setSelectedBlock({});
      setSelectedPanchayat({});
      setSelectedWard({});
      dispatch(resetLocations("block"));
      dispatch(resetLocations("panchayat"));
      dispatch(resetLocations("ward"));
    }
    if (cfield == "block") {
      setSelectedPanchayat({});
      setSelectedWard({});
      dispatch(resetLocations("panchayat"));
      dispatch(resetLocations("ward"));
    }

    if (cfield == "panchayat") {
      setSelectedWard({});
      dispatch(resetLocations("ward"));
    }
  };

  const handleDistrictChange = (e) => {
    if (e.target.value == 0) {
      reset();
    } else if (e.target.value == 999) {
      setSelectedDistrict({ id: 999, name: "All" });
    } else {
      setSelectedDistrict(
        districtData.filter((d) => d.id == e.target.value)[0]
      );
      if (!props?.removeOptions?.includes("division")) {
        dispatch(getDivision(e.target.value, props.dept));
      }
      resetOther("dist");
    }
  };

  const handleDivisionChange = (e) => {
    if (e.target.value == 0) {
      setSelectedDivision({});
    } else {
      setSelectedDivision(
        divisionData.filter((d) => d.id == e.target.value)[0]
      );
      dispatch(getBlock(e.target.value, props?.dept));
    }
    resetOther("div");
  };

  const handleBlockChange = (e) => {
    if (e.target.value == 0) {
      setSelectedBlock({});
    } else {
      setSelectedBlock(blockData.filter((d) => d.id == e.target.value)[0]);
      dispatch(getPanchayat(e.target.value, props?.dept));
    }
    resetOther("block");
  };

  const handlePanchayatChange = (e) => {
    if (e.target.value == 0) {
      setSelectedPanchayat({});
    } else {
      setSelectedPanchayat(
        panchayatData.filter((d) => d.id == e.target.value)[0]
      );
      dispatch(getWard(e.target.value, props?.dept));
    }

    resetOther("panchayat");
  };

  const handleWardChange = (e) => {
    if (e.target.value == 0) {
      setSelectedWard({});
    } else {
      setSelectedWard(wardData.filter((d) => d.id == e.target.value)[0]);
    }
  };

  useEffect(() => {
    // localStorage.setItem("locationData", JSON.stringify({ block: selectedBlock["id"], division: selectedDivision["id"], district: selectedDistrict["id"], panchayat: selectedPanchayat["id"], ward: selectedWard["id"] }))
    props.onChange({
      block: selectedBlock["id"],
      division: selectedDivision["id"],
      district: selectedDistrict["id"],
      panchayat: selectedPanchayat["id"],
      ward: selectedWard["id"],
    });
  }, [
    selectedBlock,
    selectedDivision,
    selectedDistrict,
    selectedPanchayat,
    selectedWard,
  ]);

  let distListObj = [];
  if (props.enableAll) distListObj.push({ id: 999, name: "All" });

  distListObj = [...distListObj, ...districtData];

  return (
    <Grid container spacing={1}>
      <Grid item md={2} sm={6} xs={12}>
        <TextField
          fullWidth
          label="District"
          name="district"
          onChange={handleDistrictChange}
          select
          value={selectedDistrict.id ? selectedDistrict.id : ""}
          variant="outlined"
        >
          {_.sortBy(distListObj, "name").map((e) => (
            <MenuItem key={e.id} value={e.id}>
              {e.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {props.removeOptions &&
        props.removeOptions.includes("division") ? null : (
        <Grid item md={2} sm={6} xs={12}>
          <TextField
            fullWidth
            label="Division"
            name="division"
            onChange={handleDivisionChange}
            select
            value={selectedDivision.id ? selectedDivision.id : ""}
            variant="outlined"
          >
            {selectedDistrict?.id &&
              _.sortBy(divisionData, "name").map((e) => (
                <MenuItem key={e.id} value={e.id}>
                  {e.name}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
      )}
      {props.showDistrictOnly === true ? null : (
        <>
          {props.removeOptions && props.removeOptions.includes("blocks") ? (
            ""
          ) : (
            <Grid item md={2} sm={6} xs={12}>
              <TextField
                fullWidth
                label="Block"
                name="block"
                onChange={handleBlockChange}
                select
                disabled={selectedDistrict.id == 999}
                // SelectProps={{ native: true }}
                value={selectedBlock.id ? selectedBlock.id : ""}
                variant="outlined"
              >
                {selectedDistrict?.id &&
                  selectedDivision?.id &&
                  _.sortBy([{ id: "", name: "" }, ...blockData], "name").map(
                    (category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    )
                  )}
              </TextField>
            </Grid>
          )}
          {props.removeOptions && props.removeOptions.includes("panchayats") ? (
            ""
          ) : (
            <Grid item md={2} sm={6} xs={12}>
              <TextField
                fullWidth
                label={`Panchayat`}
                name="panchayat"
                onChange={handlePanchayatChange}
                disabled={selectedDistrict.id == 999}
                select
                value={selectedPanchayat.id ? selectedPanchayat.id : ""}
                variant="outlined"
              >
                {selectedDistrict?.id &&
                  selectedDivision?.id &&
                  selectedBlock?.id &&
                  _.sortBy(
                    [{ id: "", name: "" }, ...panchayatData],
                    "name"
                  ).map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          )}
          {props.removeOptions && props.removeOptions.includes("wards") ? (
            ""
          ) : (
            <Grid item md={2} sm={6} xs={12}>
              <TextField
                fullWidth
                label="Ward"
                name="ward"
                onChange={handleWardChange}
                disabled={selectedDistrict.id == 999}
                select
                value={selectedWard.id ? selectedWard.id : ""}
                variant="outlined"
              >
                {selectedDistrict?.id &&
                  selectedDivision?.id &&
                  selectedBlock?.id &&
                  selectedPanchayat?.id &&
                  _.sortBy([{ id: "", name: "" }, ...wardData], "name").map(
                    (category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    )
                  )}
              </TextField>
            </Grid>
          )}
        </>
      )}
      <Grid
        item
        md={1}
        sm={6}
        xs={12}
        style={{ display: "flex", alignItems: "center", marginLeft: 10 }}
      >
        <Button
          onClick={() => {
            reset();
          }}
          color="primary"
          size="large"
          sx={{ mr: 3 }}
          variant="contained"
          type="button"
        >
          Reset
        </Button>
      </Grid>
      <ShowWhen
        when={
          district?.status === "loading" ||
          division?.status === "loading" ||
          block?.status === "loading" ||
          panchayat?.status === "loading" ||
          ward?.status === "loading"
        }
        component={<Loader />}
      />
    </Grid>
  );
};

export default LocationFilter;
