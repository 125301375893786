import {
  Box,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import BrandBanner from "../../components/BrandBanner";
import LoginUI from "./LoginUI";
import getTitle from "../../components/common/Title";

const Login = () => {

  return (
    <>
      {
        getTitle({ dept: "login", end: "Har Ghar Nal Jal Yojna" })
      }
      <Box style={{
        backgroundImage: 'url("/bg_image.jpg")',
        backgroundSize: "100%",
        height: "100vh"
      }}
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* <AuthBanner /> */}
        <Box
          display="flex"
          flexDirection={"column"}
          justifyContent="center"
          alignItems="center"
          marginTop={20}
        >
          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid
                container
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: 1,
                    }}
                  >
                    <BrandBanner />
                  </Box>
                  <Box
                    sx={{
                      flexGrow: 1,
                      mt: 3,
                    }}
                  >
                    <LoginUI />
                    {/* <Grid container marginTop={1} flexDirection="column">
                      <Grid item>
                        Forgot Password? <Link href={"/authentication/signup"}>Reset now</Link>
                      </Grid>
                    </Grid> */}

                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <span>
              Powered by <b>Geron Engineering Private Limited &#174;</b>
            </span>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default Login;
