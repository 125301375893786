import { createSlice } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../config";
import { get, post } from "./service";
import toast from "react-hot-toast";

export const initialState = {
  operationalDataReport: {
    status: null,
    data: null,
  },
  avgSchemeStats: {
    status: null,
    data: null,
  },
  avgStatUpdate: {
    status: null,
    data: null,
  },
};

const slice = createSlice({
  name: "OperationalData",
  initialState,
  reducers: {
    setOperationalData(state, action) {
      state.operationalDataReport = action.payload;
    },
    setAvgSchemeStats(state, action) {
      state.avgSchemeStats = action.payload;
    },
    setAvgSchemeStatUpdate(state, action) {
      state.avgStatUpdate = action.payload;
    },
  },
});

export const { reducer } = slice;

export const { setAvgSchemeStatUpdate, setAvgSchemeStats, setOperationalData } =
  slice.actions;

export const getOperationalData =
  (location, dateRange, dept = "", isOnlyTempData = false) =>
    async (dispatch) => {
      dispatch(
        slice.actions.setOperationalData({
          ...initialState.operationalDataReport,
          status: "loading",
        })
      );

      post(API_BASE_URL + "phed/device_operational_status", {
        ...location,
        dateRange,
        dept,
        isOnlyTempData,
      })
        .then((rawData) => rawData)
        .then(function (response) {
          response = response || {};
          dispatch(slice.actions.setOperationalData(response));
        })
        .catch(function (errResponse) {
          toast(
            errResponse?.message
              ? errResponse?.message
              : "Error in fetching device operational status data!",
            { type: "error" }
          );
          dispatch(
            slice.actions.setOperationalData({ ...errResponse, status: "failed" })
          );
        });
    };

export const getAvgSchemeStats =
  (did, divid, dateRange, isOnlyTempData = false) =>
    async (dispatch) => {
      dispatch(
        slice.actions.setAvgSchemeStats({
          ...initialState.avgSchemeStats,
          status: "loading",
        })
      );

      get(API_BASE_URL + "phed/homepage_device_stats", {
        did,
        divid,
        from: dateRange[0],
        to: dateRange[1],
        isOnlyTempData,
      })
        .then((rawData) => rawData)
        .then(function (response) {
          response = response || {};
          dispatch(slice.actions.setAvgSchemeStats(response));
        })
        .catch(function (errResponse) {
          toast(
            errResponse?.message
              ? errResponse?.message
              : "Error in fetching device stats data!",
            { type: "error" }
          );
          dispatch(
            slice.actions.setAvgSchemeStats({ ...errResponse, status: "failed" })
          );
        });
    };

//update-temp-stats
export const updateAvgSchemeStats = (data) => async (dispatch) => {
  dispatch(
    slice.actions.setAvgSchemeStatUpdate({
      ...initialState.avgStatUpdate,
      status: "loading",
    })
  );
  post(API_BASE_URL + "phed/update-temp-stats", { ...data })
    .then((rawData) => rawData)
    .then(function (response) {
      response = response || {};
      dispatch(slice.actions.setAvgSchemeStatUpdate(response));
      toast(response?.message ? response?.message : `Updated Successfully!`, {
        type: "success",
      });
    })
    .catch(function (errResponse) {
      toast(
        errResponse?.message
          ? errResponse?.message
          : "Error in updating temp stats data!",
        { type: "error" }
      );
      dispatch(
        slice.actions.setAvgSchemeStatUpdate({
          ...errResponse,
          status: "failed",
        })
      );
    });
};
