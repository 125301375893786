import toast from "react-hot-toast";
import { API_BASE_URL } from "../config";
import { post } from "../slices/service";

export const ExportRegisteredFirmware = async ({ filter = null }) => {
  try {
    const payload = {
      ...filter,
    };

    const reportRes = await post(API_BASE_URL + "phed/debugging/download-registered-devices", payload);
    if (reportRes.statusCode === 201 || reportRes.statusCode === 200) {
      toast.success(reportRes?.message || "Successfully exported!", {
        duration: 4000,
      });
      return reportRes;
    } else if (reportRes.statusCode === 400) {
      toast.error(reportRes?.message || "Error in exporting!", {
        duration: 4000,
      });
      return;
    } else {
      throw new Error(
        reportRes.message || "Error in exporting!"
      );
    }
  } catch (error) {
    toast.error(
      error.message || "Error occurred in exporting!",
      {
        duration: 4000,
      }
    );
  }
};
