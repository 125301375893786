import { createSlice } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../config";
import { post } from "./service";
import toast from "react-hot-toast";

const initialState = {
    manualDeviceTrigger: {
        status: null,
        data: null
    }
};

const slice = createSlice({
    name: "manualTrigger",
    initialState,
    reducers: {
        setManualDeviceTrigger(state, action) {
            state.manualDeviceTrigger = action.payload;
        }
    },
});

export const { reducer } = slice;

export const manualDeviceTrigger = (data) => async (dispatch) => {
    dispatch(slice.actions.setManualDeviceTrigger({ ...initialState.manualDeviceTrigger, status: "loading" }));

    post(API_BASE_URL + "phed/maintenance/manual_device_trigger", data)
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setManualDeviceTrigger(response));
            toast(response?.message ? response?.message : `Updated Successfully!`, { type: "success" });
        })
        .catch(function (errResponse) {
            toast(errResponse?.message ? errResponse?.message : "Error in updating manual device data!", { type: "error" });
            dispatch(slice.actions.setManualDeviceTrigger({ ...errResponse, status: "failed" }));
        });
};

export default slice;
