import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { checkIfRoleExist } from '../../utils/helper';
const LoginCheck = ({ children }) => {
    const usertype = localStorage.getItem("USER_TYPE")
    if (usertype && checkIfRoleExist(usertype, ["APP_SUPER_ADMIN", "APP_ADMIN"])) {        
        return <Navigate to="/dashboard" />;
    }
    else {
        return <>{children}</>
    }
};

LoginCheck.propTypes = {
    children: PropTypes.node
};

export default LoginCheck;
