import { Box, styled } from "@mui/material";


const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  }));

const CustomNoRowsOverlay = ({title = "No Records!", ...rest}) => {
    console.log("rest", title, rest)
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1, color: "red" }}>{title}</Box>
      </StyledGridOverlay>
    );
  }

  export default CustomNoRowsOverlay