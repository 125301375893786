import toast from "react-hot-toast";
import { get } from "../slices/service";
import { API_BASE_URL } from "../config";

export const getCallingWardsReports = async ({
  district,
  dateRange = null,
  dept = "phed",
}) => {
  try {
    if (!district && !dateRange) {
      throw new Error("User id or district or Date Range is missing");
    }
    const callingWardsRes = await get(
      API_BASE_URL + "phed/calling-wards-report",
      {
        district,
        date_range: dateRange,
        dept,
      }
    );
    if (callingWardsRes.statusCode === 200) {
      return callingWardsRes;
    } else if (callingWardsRes.statusCode === 404) {
      return;
    } else {
      throw new Error(
        callingWardsRes.message ||
        "Error occurred while getting the calling wards!"
      );
    }
  } catch (error) {
    toast.error(
      error.message || "Error occurred while getting the calling wards!",
      {
        duration: 4000,
      }
    );
  }
};
