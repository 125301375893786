import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateRangePicker = ({
  title = "Select Date Range: ",
  dateRange,
  setDateRange,
  withPortal = false,
  isClearable = true,
  maxDate,
  placeholderText = "Select date range",
  disabled = false,
}) => {
  const [startDate, endDate] = dateRange;
  return (
    <>
      {title.length > 0 && (
        <span
          style={{
            fontSize: "15px",
            fontWeight: "normal",
            marginRight: "10px",
          }}
        >
          {title}
        </span>
      )}
      <DatePicker
        showTwoColumnMonthYearPicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setDateRange(update);
        }}
        isClearable={isClearable}
        withPortal={withPortal}
        placeholderText={placeholderText}
        maxDate={maxDate || new Date()}
        disabled={disabled}
      />
    </>
  );
};

export default DateRangePicker;
