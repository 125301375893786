import {
  Breadcrumbs,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Container, Link } from "@mui/material";
import ChevronRight from "../../icons/ChevronRight";
import { Link as RouterLink } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import getTitle from "../../components/common/Title";

function ChangePassword(props) {
  return (
    <>
      {
        getTitle({ end: "Update Password" })
      }
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 2,
        }}
      >
        <Container maxWidth={"xl"}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Change Password
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRight fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard/phed"
                  variant="subtitle2"
                >
                  Home
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Change Password
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>

          <Container maxWidth={"sm"}>
            <Box sx={{ py: 3 }}>
              <Formik
                initialValues={{
                  oldPassword: "",
                  newPassword: "",
                  confirmPassword: "",
                  submit: null,
                }}
                validationSchema={Yup.object().shape({
                  oldPassword: Yup.string()
                    .min(5)
                    .max(12)
                    .required("Old Password is required"),
                  newPassword: Yup.string()
                    .min(5)
                    .max(12)
                    .required("New Password is required"),
                  confirmPassword: Yup.string()
                    .min(5)
                    .max(12)
                    .required("Confirm Password is required"),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  try {
                    // await login(values.email, values.password);
                    // if (mounted.current) {
                    //   setStatus({ success: true });
                    //   setSubmitting(false);
                    // }
                  } catch (err) {
                    console.error(err);
                    // if (mounted.current) {
                    //   setStatus({ success: false });
                    //   setErrors({ submit: "Username and Password did not match" });
                    //   setSubmitting(false);
                    // }
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit} {...props}>
                    <TextField
                      autoFocus
                      error={Boolean(touched.oldPassword && errors.oldPassword)}
                      fullWidth
                      helperText={touched.oldPassword && errors.oldPassword}
                      label="Old Password"
                      margin="normal"
                      name="oldPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.oldPassword}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(touched.newPassword && errors.newPassword)}
                      fullWidth
                      helperText={touched.newPassword && errors.newPassword}
                      label="New Password"
                      margin="normal"
                      name="newPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                    />
                    <TextField
                      error={Boolean(
                        touched.confirmPassword && errors.confirmPassword
                      )}
                      fullWidth
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      label="Confirm Password"
                      margin="normal"
                      name="confirmPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                    />
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        color="secondary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Update
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Container>
        </Container>
      </Box>
    </>
  );
}

export default ChangePassword;
