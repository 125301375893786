import useSettings from "../../../hooks/useSettings";
import CommonButtonGroup, { createRenderContent } from "../../../components/common/CommonButtonGroup";
import DepartmentLayout from "../../../components/common/DepartmentLayout";
import { menus } from "../phed";

function Udhd() {
  const { settings } = useSettings();

  const dept = "udhd"
  const titleForPdf = "Urban Development And Housing Department"
  const renderContent = createRenderContent(dept, titleForPdf);

  return (
    <DepartmentLayout settings={settings} dept={dept} >
      <CommonButtonGroup menus={menus} renderContent={renderContent} />
    </DepartmentLayout>
  );
}

export default Udhd;
