import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Link,
  Button,
  Modal,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDeviceData,
  getRegisteredDevices,
} from "../../../slices/firmwareRegistration";
import { convertToJSON, getDateTime, hasProperties } from "../../../utils/helper";
import ShowWhen from "../../../components/ShowWhen";
import Loader from "../../../components/Loader/Loader";
import CustomFilter from "../../../components/common/CustomFilter";
import RegDevicesList from "../../../components/common/RegDevicesList";
import FirmwareExports from "../../../components/FirmwareExports";
import getTitle from "../../../components/common/Title";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const initialCheckedOption = {
  reg: false,
  telemetry: false,
};

export default function RegisteredDeviceList() {
  const [status, setStatus] = useState("all");
  const [rows, setRegDeviceData] = useState([]);
  const pageSize = 40;
  const [allDeviceData, setAllDeviceData] = useState([]);
  const {
    firmwareData: firmwareDataApiResponse,
    deleteDeviceData: deleteDeviceDataApiResponse,
  } = useSelector((state) => state.firmwareRegistration);
  const [openModal, setOpenModal] = useState(false);
  const [currentDevice, setCurrentDevice] = useState(null);
  const filterProperties = ["barcode", "imei", "simNumber"];
  const [filter, setFilter] = useState(null);
  const [exportFilter, setExportFilter] = useState(null);
  const [continuationTokens, setContinuationTokens] = useState([null]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isInitialFetch, setIsInitialFetch] = useState(true);
  const [totalDocuments, setTotalDocuments] = useState({
    documentsCount: 0,
  });
  const [nextNewToken, setNewToken] = useState(null)
  const [loadingMsg, setLoaderMsg] = useState(null)

  const handleSearch = () => {
    const hasValidFilter = Object.values(filter || {}).some(
      (value) => value.length >= 3
    );
    if (continuationTokens?.length > 0) {
      setContinuationTokens((prevTokens) => [prevTokens[0]]);
      setCurrentIndex(0);
    }
    if (hasValidFilter) {
      dispatch(getRegisteredDevices({ filter }));
    }
    setExportFilter(filter)
  }

  const handleCloseModal = () => {
    setCheckedOptions(initialCheckedOption);
    setOpenModal(false);
  };
  const dispatch = useDispatch();
  const [checkedOptions, setCheckedOptions] = useState(initialCheckedOption);

  useEffect(() => {
    if (continuationTokens?.length > 0) {
      setContinuationTokens((prevTokens) => [prevTokens[0]]);
      setCurrentIndex(0);
    }

    if (isInitialFetch || !hasProperties(filter)) {
      dispatch(getRegisteredDevices({ filter }));
      setIsInitialFetch(false);
    }
  }, [dispatch, filter]);

  useEffect(() => {
    setLoaderMsg("Fetching Records! Please wait for a while...")
    if (firmwareDataApiResponse && firmwareDataApiResponse.data) {
      let tempTotalDocs = firmwareDataApiResponse?.headers?.["x-ms-resource-usage"];
      tempTotalDocs = convertToJSON(tempTotalDocs);
      setTotalDocuments(tempTotalDocs);

      const newToken = firmwareDataApiResponse.nextContinuationToken;
      if (continuationTokens[currentIndex] !== newToken && newToken) {
        setNewToken(newToken);
      }

      const rws = firmwareDataApiResponse.data.map((e, i) => {
        const dt = getDateTime(e._ts);
        return {
          slno: pageSize * currentIndex + i + 1,
          ...e,
          obj: e,
          created_datetime: `${dt["date"]} ${dt["time"]}`,
          lastHeartBeat_ts: e?.lastHeartBeat_ts ? e?.lastHeartBeat_ts : "",
          lastHearBeatDate: e?.lastHearBeatDate ? e?.lastHearBeatDate : "-NA-",
        };
      });
      setAllDeviceData(rws);
      setRegDeviceData(rws);
    }
  }, [firmwareDataApiResponse]);

  const columns = [
    {
      field: "slno",
      headerName: "Sl No",
      width: 20,
      sortable: false,
      headerClassName: "scheme-data-table",
    },
    {
      field: "barcode",
      headerName: "Barcode",
      width: 100,
    },
    {
      field: "imei",
      headerName: "IMEI",
      width: 160,
    },
    {
      field: "deviceId",
      headerName: "Software Device Id",
      width: 130,
      hide: false,
    },
    {
      field: "simNumber",
      headerName: "SIM Number",
      width: 190,
    },
    {
      field: "carrierName",
      headerName: "Carrier Name",
      width: 100,
    },

    {
      field: "user_name",
      headerName: "User Name",
      width: 180,
    },
    {
      field: "mobile_number",
      headerName: "Mobile Number",
      width: 130,
      hide: true
    },
    {
      field: "created_datetime",
      headerName: "Date Time",
      width: 180,
    },
    {
      field: "lastHearBeatDate",
      headerName: "Last Heart Beat",
      width: 180,
      hide: false,
    },
    {
      field: "certificate_path",
      headerName: "Certificate",
      width: 100,
      renderCell: (params) => {
        const data = params["formattedValue"];
        return data ? (
          <Link target="_blank" href={data}>
            Download
          </Link>
        ) : (
          "Not Found"
        );
      },
    },
    {
      field: "obj",
      sortable: false,
      disableExport: true,
      width: 130,
      headerName: "Action",
      renderCell: (params) => {
        if (params["formattedValue"]["assignedWard"] == null)
          return (
            <Button
              sx={{ margin: "0px !important", padding: "0px !important" }}
              disabled={params["formattedValue"]["assignedWard"]}
              onClick={() => handleClick(params["formattedValue"])}
            >
              Delete
            </Button>
          );
        else
          return (
            <Tooltip title="Already assigned to ward">
              <Typography variant="p" color={"red"} sx={{ textTransform: "uppercase", fontSize: "12px" }}>
                Cannot delete
              </Typography>
            </Tooltip>
          );
      },
    },
  ];


  useEffect(() => {
    let rowsTemp = [];
    if (status === "pass") {
      rowsTemp = allDeviceData.filter(
        (e) =>
          "barcode" in e &&
          "carrierName" in e &&
          "certificate_path" in e &&
          "simNumber" in e
      );
    } else if (status === "fail") {
      rowsTemp = allDeviceData.filter(
        (e) =>
          !(
            "barcode" in e &&
            "carrierName" in e &&
            "certificate_path" in e &&
            "simNumber" in e
          )
      );
    } else {
      rowsTemp = allDeviceData;
    }

    setRegDeviceData(rowsTemp);
  }, [allDeviceData, status]);

  const handleClick = (data) => {
    setCheckedOptions(initialCheckedOption);
    setCurrentDevice(data);
    setOpenModal(true);
  };

  const handleSubmit = () => {
    setCheckedOptions(initialCheckedOption);
    dispatch(
      deleteDeviceData({
        deleteFlag: { ...checkedOptions },
        device: currentDevice,
      })
    );
    handleCloseModal();
  };

  useEffect(() => {
    setLoaderMsg("Deleting records! Please wait its take a while...")
    if (deleteDeviceDataApiResponse && deleteDeviceDataApiResponse.status === "SUCCESS") {
      dispatch(getRegisteredDevices({ filter, continuationToken: nextNewToken }));
    }
  }, [deleteDeviceDataApiResponse, dispatch]);

  const fetchNext = async () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1));
    setContinuationTokens((prevTokens) => [...prevTokens, nextNewToken]);
    dispatch(getRegisteredDevices({ filter, continuationToken: nextNewToken }));
  };

  const fetchPrevious = () => {
    if (currentIndex >= 0) {
      setCurrentIndex((prevIndex) => (prevIndex - 1));
      const prevToken = continuationTokens[continuationTokens.length - 2];
      setContinuationTokens((prevTokens) => prevTokens.slice(0, -1));
      dispatch(getRegisteredDevices({ filter, continuationToken: prevToken }));
    }
  };

  return (
    <>
      {
        getTitle({ dept: "Maintenance", end: "Registered Devices | Har Ghar Nal Jal Yojna" })
      }
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          p: 2,
        }}
      >
        <Box>
          {/* <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
          >
            <Button
              variant={status === "all" ? "contained" : ""}
              onClick={() => setStatus("all")}
            >
              All
            </Button>
            <Button
              variant={status === "pass" ? "contained" : ""}
              onClick={() => setStatus("pass")}
            >
              Pass
            </Button>
            <Button
              variant={status === "fail" ? "contained" : ""}
              onClick={() => setStatus("fail")}
            >
              Fail
            </Button>
          </ButtonGroup> */}
          <Typography variant="h6">Registered Device List:</Typography>
          <Box sx={{ py: 3 }}>
            <div style={{ height: 750, width: "100%" }}>
              <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                <CustomFilter
                  filter={filter}
                  filterProperties={filterProperties}
                  setFilter={setFilter}
                  handleSearch={handleSearch}
                />
              </Box>
              <FirmwareExports filter={exportFilter} />
              <RegDevicesList isShowTable fetchNext={fetchNext} fetchPrevious={fetchPrevious} hasMoreResults={!firmwareDataApiResponse?.hasMoreResults} columns={columns} currentIndex={currentIndex} pageSize={40} rows={rows} totalDocuments={totalDocuments?.documentsCount || 0} />
            </div>
          </Box>
        </Box>
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Select what to delete
          </Typography>
          <Box>
            <FormGroup
              onChange={(e) => {
                let tempChecked = { ...checkedOptions };
                tempChecked[e.target.value] = e.target.checked;
                setCheckedOptions(tempChecked);
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox value="reg" checked={checkedOptions["reg"]} />
                }
                label="Delete Registration"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    value="telemetry"
                    checked={checkedOptions["telemetry"]}
                  />
                }
                label="Delete Telemetry Data"
              />
            </FormGroup>
          </Box>
          {currentDevice ? (
            <Box sx={{ py: 2 }}>
              <Typography>Selected Device</Typography>
              <Grid container>
                <Grid item md={6}>
                  <Typography>IMEI: {currentDevice.imei}</Typography>
                  <Typography>
                    Barcode:{" "}
                    {currentDevice.barcode
                      ? currentDevice.barcode
                      : "Not Found"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ) : (
            ""
          )}
          <Box
            style={{
              marginTop: 10,
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              style={{ marginLeft: 10 }}
              onClick={() => {
                setCurrentDevice(null);
                handleCloseModal();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={
                Object.values(checkedOptions).filter((e) => e).length == 0
              }
              variant="contained"
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
      <ShowWhen
        when={
          deleteDeviceDataApiResponse?.status === "loading" ||
          firmwareDataApiResponse?.status === "loading"
        }
        component={<Loader text={loadingMsg} />}
      />
    </>
  );
}
