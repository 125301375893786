import { Box, Button, Grid, Typography, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSchemes, resetSchemes } from "../../slices/siteInfo";
import LocationFilter from "../LocationFilter";
import {
  capitalizedWordsFirstLetterWithSpace,
  generateCapitalizedFilename,
  updateFinalNFWList,
  syncNFLists,
  syncAccordionData,
} from "../../utils/helper";
import ShowWhen from "../ShowWhen";
import Loader from "../Loader/Loader";
import WardList from "../WardList";
import CustomAccordion from "./CustomAccordian";
import MyDocument from "../PdfGeneratorDocument";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { priColor } from "../../constants";
import { generateNFReport } from "../../actions";
import toast from "react-hot-toast";
import getTitle from "./Title";
import { getCurrentNFWHandler, getOperationalDataHandler, getReportsHandler, updateAvgSchemeStatsHandler } from "./NonFunctionalReports";

export default function NonReportingReports(props) {
  const dispatch = useDispatch();
  const [schemeLocation, setSchemeLocation] = useState({});
  const { ward, schemes } = useSelector((state) => state.siteInfo);
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);

  // Fetched Data Var
  const [fetchedActual_NRW, setFetchedActualNRW] = useState([]);
  const [fetchedCurrent_NRW, setFetchedCurrentNRW] = useState([]);
  const [fetchedNew_NRW, setFetchedNewNRW] = useState([]);

  const [fetchedCurrent_NRW_Ids, setFetchedCurrentNRW_Ids] = useState([]);
  const [fetchedNew_NRW_Ids, setFetchedNewNRW_Ids] = useState([]);
  const [fetchedActual_NRW_Ids, setFetchedActualNRW_Ids] = useState([]);

  // Selected Data Var
  const [selectedActual_NRW, setSelectedActualNRW] = useState([]);
  const [selectedCurrent_NRW, setSelectedCurrentNRW] = useState([]);
  const [selectedNew_NRW, setSelectedNewNRW] = useState([]);
  const [selectedFinal_NRW, setSelectedFinalNRW] = useState([]);
  const [selectedFinal_NRW_Ids, setSelectedFinalNRW_Ids] = useState([]);

  const [expanded, setExpanded] = useState(false);
  const [panel, setPanel] = useState("");
  const [tempStats, setTempStats] = useState(null);

  const fetchedIdsListName = [
    fetchedActual_NRW_Ids,
    fetchedNew_NRW_Ids,
    fetchedCurrent_NRW_Ids,
  ];
  const fetchedListName = [
    fetchedActual_NRW,
    fetchedNew_NRW,
    fetchedCurrent_NRW,
  ];

  const listsName = [
    selectedActual_NRW,
    selectedNew_NRW,
    selectedCurrent_NRW,
    selectedFinal_NRW_Ids,
  ];

  const funcName = [
    setSelectedActualNRW,
    setSelectedNewNRW,
    setSelectedCurrentNRW,
  ];

  // Sync If any Ward Unchecked
  const handleWardUnchecked = (listIndex) => {
    syncNFLists({ listIndex: listIndex, listsName: listsName, fetchedIdsListName, updateFunctions: funcName });
  };

  // Sync Non-functional reports if accordion opened
  const handleAccordionOpen = (listIndex) => {
    syncAccordionData({ listIndex: listIndex, listsName: listsName, fetchedIdsListName, updateFunctions: funcName });
  };

  // To update final Ward List
  const updateFinalNFW_handler = () => {
    updateFinalNFWList({ listsName: listsName, selectedFinal_NFW: selectedFinal_NRW, fetchedListName, setSelectedFinal_Ids: setSelectedFinalNRW_Ids, setSelectedFinal_Wards: setSelectedFinalNRW })
  };

  useEffect(() => {
    resetAll();
  }, []);

  useEffect(() => {
    if (panel === "actualNRWList") {
      handleWardUnchecked(0);
    }
  }, [selectedActual_NRW]);

  useEffect(() => {
    if (panel === "newNRWList") {
      handleWardUnchecked(1);
    }
  }, [selectedNew_NRW]);

  useEffect(() => {
    if (panel === "currentNRWList") {
      handleWardUnchecked(2);
    }
  }, [selectedCurrent_NRW]);

  useEffect(() => {
    switch (panel) {
      case "actualNRWList":
        handleAccordionOpen(0);
        break;
      case "newNRWList":
        handleAccordionOpen(1);
        break;
      case "currentNRWList":
        handleAccordionOpen(2);
        break;

      default:
        break;
    }
  }, [panel, fetchedNew_NRW]);

  const resetAll = () => {
    setFetchedActualNRW([]);
    setFetchedCurrentNRW([]);
    setFetchedCurrentNRW_Ids([]);
    setFetchedNewNRW([]);
    setSelectedActualNRW([]);
    setSelectedCurrentNRW([]);
    setSelectedNewNRW([]);
    setSelectedFinalNRW([]);
    dispatch(resetSchemes());
  };

  const resetSelection = () => {
    setSelectedActualNRW([]);
    setSelectedCurrentNRW([]);
    setSelectedNewNRW([]);
    setSelectedFinalNRW([]);
  };

  const handleGetReports = async (type) => {
    if (schemeLocation && schemeLocation.district && type === "nonfunctional") {
      getReportsHandler(type, schemeLocation.district, setLoading, { setFetchedActualNRW, setFetchedActualNRW_Ids })
    }
  };



  //TODO: NEEDS TO UPDATE THIS
  const onChangeLocation = (data) => {
    const keys = Object.keys(data);
    let allReset = true;
    for (let i = 0; i < keys?.length; i++) {
      allReset = allReset && !data[keys[i]];
    }

    if (
      data?.block !== schemeLocation?.block &&
      data?.panchayat !== schemeLocation?.panchayat
    ) {
      setFetchedNewNRW([]);
    }

    if (data?.district !== schemeLocation?.district) {
      resetAll();
    }

    if (allReset) {
      resetAll();
    }
    setSchemeLocation(data);
  };


  useEffect(() => {
    if (
      schemeLocation.district ||
      schemeLocation.block ||
      schemeLocation.panchayat ||
      schemeLocation.ward
    ) {
      let d = {};
      if (schemeLocation.district) {
        d["district"] = schemeLocation.district;
      }
      if (schemeLocation.division) {
        d["division"] = schemeLocation.division;
      }
      if (schemeLocation.block) {
        d["block"] = schemeLocation.block;
      }
      if (schemeLocation.panchayat) {
        d["panchayat"] = schemeLocation.panchayat;
      }
      if (schemeLocation.ward) {
        d["ward"] = schemeLocation.ward;
      }
      // dispatch(setFilters(schemeLocation));
      if (schemeLocation.block) {
        dispatch(getSchemes(d));
      }
    }
  }, [dispatch, schemeLocation]);

  useEffect(() => {
    setFetchedNewNRW(schemes?.data);
    let ids = schemes?.data?.map((s) => s.id);
    setFetchedNewNRW_Ids(ids);
  }, [schemes]);

  const handleOpenAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setPanel(panel);
  };

  const handleTempStatsUpdate = async (wardsData) => {
    try {
      setLoading(true);
      const wDistrict = wardsData?.[0]?.district;
      const wDivisionId =
        wardsData?.[0]?.divisionId || wardsData?.[0]?.division;
      if (wDistrict && wDivisionId) {
        if (
          tempStats?.data
        ) {
          const district = tempStats?.data.district;
          if (district) {
            let smsIds = selectedFinal_NRW?.map((wd) => {
              return wd?.smsid;
            });
            let finalVal = {
              ...tempStats?.data,
              nonReportingWards: smsIds,
            };
            await updateAvgSchemeStatsHandler({ district: district, ...finalVal }, setLoading, updated, setUpdated)
            await generateNFReport({ dept: props?.dept, wardsData: selectedFinal_NRW, reportType: "NR" });
          } else {
            toast.error("District or division not found or matched!")
          }
        } else {
          toast.error("Temp stats data not found!")
        }
      } else {
        toast.error("District or division not found or matched!")
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    if (schemeLocation?.district) {
      getOperationalDataHandler({ district: schemeLocation?.district, setLoading, dept: props?.dept, setTempStats })
      getCurrentNFWHandler(schemeLocation?.district, setLoading, "nonReportingWards", { setFetchedCurrentNRW, setFetchedCurrentNRW_Ids })
    }

  }, [schemeLocation?.district, updated]);

  return (
    <>
      {getTitle({ dept: props?.dept + " Reports", end: "Non Reporting Reports" })}
      <Box sx={{ p: 0 }}>
        <LocationFilter
          onChange={onChangeLocation}
          initialValues={schemeLocation}
          dept={props?.dept}
        />
        <Box
          sx={{
            paddingTop: 1,
            display: "flex",
            justifyContent: "left",
            columnGap: "10px",
          }}
        >
          <Button
            disabled={!schemeLocation?.district || loading}
            variant="outlined"
            size="small"
            onClick={() => handleGetReports("nonfunctional")}
          >
            Get Actual Report
          </Button>
          <Button
            // disabled={(!selectedFinal_NRW && !selectedNew_NRW && !selectedCurrent_NRW) || loading }
            variant="outlined"
            size="small"
            onClick={() => updateFinalNFW_handler()}
          >
            Update list
          </Button>
          <ShowWhen
            when={selectedFinal_NRW?.length}
            component={
              <Box sx={{ display: "flex", columnGap: "10px" }}>
                <PDFDownloadLink
                  document={
                    <MyDocument
                      data={selectedFinal_NRW}
                      district={capitalizedWordsFirstLetterWithSpace(
                        selectedFinal_NRW[0]?.districtName || " "
                      )}
                      titleForPdf={capitalizedWordsFirstLetterWithSpace(
                        props?.titleForPdf
                      )}
                      reportTitle="Non-Reporting Report"
                    />
                  }
                  fileName={generateCapitalizedFilename({
                    name: selectedFinal_NRW[0]?.districtName,
                  })}
                >
                  {({ blob, url, loading, error }) => (
                    <ShowWhen
                      when={!loading}
                      component={
                        <>
                          <Button
                            disabled={loading}
                            variant="outlined"
                            size="small"
                          >
                            {`download Report (${selectedFinal_NRW?.length || 0
                              })`}
                          </Button>
                        </>
                      }
                      otherwise={
                        <>
                          <Button
                            disabled={loading}
                            variant="outlined"
                            size="small"
                          >
                            loading...
                          </Button>
                        </>
                      }
                    />
                  )}
                </PDFDownloadLink>
                <Button
                  type="button"
                  onClick={() => handleTempStatsUpdate(selectedFinal_NRW)}
                  disabled={loading}
                  variant="outlined"
                  size="small"
                >
                  Update Final Report
                </Button>
                <Button
                  type="button"
                  onClick={() => resetSelection()}
                  disabled={loading}
                  variant="outlined"
                  size="small"
                >
                  Reset Report
                </Button>
              </Box>
            }
          />
        </Box>

        <Grid mt={3} direction={"column"} marginBottom={"10px"}>
          <Grid item xs={12}>
            <Paper
              elevation={0}
              variant="outlined"
              style={{ padding: "10px", marginBottom: "5px" }}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography gutterBottom>
                Installed :{" "}
                <span style={{ color: priColor }}>
                  {tempStats?.data?.functional +
                    tempStats?.data?.nonFunctional +
                    tempStats?.data?.nonReporting || 0}
                </span>
              </Typography>
              <Typography gutterBottom>
                Count of functional :{" "}
                <span style={{ color: priColor }}>
                  {tempStats?.data?.functional || 0}
                </span>
              </Typography>
              <Typography gutterBottom>
                Count of non-functional :{" "}
                <span style={{ color: priColor }}>
                  {tempStats?.data?.nonFunctional || 0}
                </span>
              </Typography>
              <Typography gutterBottom>
                Count of non-reporting :{" "}
                <span style={{ color: priColor }}>
                  {tempStats?.data?.nonReporting || 0}
                </span>
              </Typography>
            </Paper>
          </Grid>
          <Grid item xl={12} md={12} xs={12}>
            <CustomAccordion
              expanded={expanded}
              expandedItem={"newNRWList"}
              title={`Select Wards to add in non-reporting report (${fetchedNew_NRW?.length || 0
                })`}
              id="panel1bh-header"
              onChange={handleOpenAccordion("newNRWList")}
            >
              <WardList
                listType="nr"
                title=" "
                loading={loading}
                checkboxSelection={true}
                selectionModel={selectedNew_NRW}
                setSelectionModel={setSelectedNewNRW}
                rowSelectable={true}
                sx={{ height: "100%" }}
                schemes={fetchedNew_NRW}
                showExport={true}
                tempStats={tempStats?.data}
                schemeLocation={schemeLocation}
                distName={"_Select_Wards_"}
                rowSelectableIds={tempStats?.data?.actual_functional.concat(tempStats?.data?.nonFunctionalWards) || []}
              />
            </CustomAccordion>
          </Grid>
        </Grid>

        <Grid direction={"column"} marginBottom={"10px"}>
          <Grid item xl={12} md={12} xs={12}>
            <CustomAccordion
              expanded={expanded}
              expandedItem={"currentNRWList"}
              title={`Current non-reporting reports (${fetchedCurrent_NRW?.length || 0
                })`}
              id="panel1bh-header"
              onChange={handleOpenAccordion("currentNRWList")}
            >
              <WardList
                title=" "
                loading={loading}
                checkboxSelection={true}
                selectionModel={selectedCurrent_NRW}
                setSelectionModel={setSelectedCurrentNRW}
                rowSelectable={true}
                sx={{ height: "100%" }}
                schemes={fetchedCurrent_NRW}
                showExport={true}
                tempStats={tempStats?.data}
                schemeLocation={schemeLocation}
                distName={"_current_non_reporting_report_"}
              />
            </CustomAccordion>
          </Grid>
        </Grid>

        <Grid direction={"column"} marginBottom={"10px"}>
          <Grid item xl={12} md={12} xs={12}>
            <CustomAccordion
              expanded={expanded}
              expandedItem={"actualNRWList"}
              title={`Non-Reporting Report (${fetchedActual_NRW?.length || 0
                })`}
              id="panel1bh-header"
              onChange={handleOpenAccordion("actualNRWList")}
            >
              <WardList
                title=" "
                loading={ward?.status === "loading"}
                checkboxSelection={true}
                sx={{ height: "100%" }}
                schemes={fetchedActual_NRW}
                fileName={fetchedActual_NRW?.[0]?.districtName || ""}
                distName={"_non_reporting_report"}
                showExport={true}
                tempStats={tempStats?.data}
                selectionModel={selectedActual_NRW}
                setSelectionModel={setSelectedActualNRW}
                rowSelectableIds={tempStats?.data?.actual_functional.concat(tempStats?.data?.nonFunctionalWards) || []}
              />
            </CustomAccordion>
          </Grid>
        </Grid>

        <ShowWhen
          when={loading}
          component={<Loader />}
        />
      </Box>
    </>
  );
}
