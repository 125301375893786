import SuperAdminGuard from "./authAPI/guards/SuperAdminGuard";
import GuestGuard from "./authAPI/guards/GuestGuard";
import LoginCheck from "./authAPI/guards/LoginCheck";
import DashboardLanding from "./pages/dashboard/DashboardLanding";
import MobileApp from "./pages/dashboard/mobileapp";
import Phed from "./pages/dashboard/phed";
import Prd from "./pages/dashboard/prd";
import Udhd from "./pages/dashboard/udhd";
import LandingPage from "./pages/LandingPage";
import Login from "./pages/login";
import ChangePassword from "./pages/profile/ChangePassword";
import Profile from "./pages/profile/Profile";
import AppAdminGuard from "./authAPI/guards/AppAdminGuard";
import RegisteredDeviceList from "./pages/dashboard/firmware/RegisteredDeviceList";
import DashboardHome from "./pages/dashboard/home";
import UserManagement from "./pages/dashboard/user-management";

const routes = [
  {
    path: "authentication",
    children: [
      {
        path: "login",
        element: (
          <LoginCheck>
            <Login />
          </LoginCheck>
        ),
      },
    ],
  },
  {
    path: "dashboard",
    element: <DashboardLanding />,
    children: [
      {
        path: "",
        element: (
          <DashboardHome />
        ),
      },
      {
        path: "phed",
        element: (
          <AppAdminGuard>
            <Phed />
          </AppAdminGuard>
        ),
      },
      {
        path: "prd",
        element: (
          <AppAdminGuard>
            <Prd />
          </AppAdminGuard>
        ),
      },
      {
        path: "udhd",
        element: (
          <AppAdminGuard>
            <Udhd />
          </AppAdminGuard>
        ),
      },
      {
        path: "mobile-app",
        element: <SuperAdminGuard>
          <MobileApp />
        </SuperAdminGuard>,
      },
      {
        path: "firmware",
        element: <SuperAdminGuard>
          <RegisteredDeviceList />
        </SuperAdminGuard>,
      },
      {
        path: "users-management",
        element: <SuperAdminGuard>
          <UserManagement />
        </SuperAdminGuard>,
      },
      {
        path: "change-password",
        element: (
          <SuperAdminGuard>
            <ChangePassword />
          </SuperAdminGuard>
        ),
      },
      {
        path: "profile",
        element: (
          <SuperAdminGuard>
            <Profile />
          </SuperAdminGuard>
        ),
      },
    ],
  },
  {
    path: "*",
    element: (
      <GuestGuard>
        <LandingPage />
      </GuestGuard>
    ),
  },
];

export default routes;
