import { createSlice } from '@reduxjs/toolkit';
import { API_BASE_URL } from '../config';
import { get } from './service';
import toast from 'react-hot-toast';

const initialState = {
    reportDownloadData: {
        status: null,
        data: null
    },
    deadDevices: {
        status: null,
        data: null
    },
    currentNFW: {
        status: null,
        data: null
    },
    downloadDeleteLog: {
        status: null,
        data: null
    }
};

const slice = createSlice({
    name: 'reportdownload',
    initialState,
    reducers: {
        setReportDownload(state, action) {
            state.reportDownloadData = action.payload
        },
        setDeleteLog(state, action) {
            state.downloadDeleteLog = action.payload
        },
        setCurrentNFW(state, action) {
            state.currentNFW = action.payload
        },
        setDeadDevices(state, action) {
            state.deadDevices = action.payload
        }
    }
});

export const { reducer } = slice;

export const resetDownloadData = () => async (dispatch) => {
    dispatch(slice.actions.setReportDownload({ ...initialState.reportDownloadData }));
}

export const resetDeadDevices = () => async (dispatch) => {
    dispatch(slice.actions.setDeadDevices({ ...initialState.deadDevices }));
}

export const resetCurrentNFW = () => async (dispatch) => {
    dispatch(slice.actions.setCurrentNFW({ ...initialState.currentNFW }));
}
export const resetDeleteLogDownloadData = () => async (dispatch) => {
    dispatch(slice.actions.setDeleteLog({ ...initialState.downloadDeleteLog }));
}

export const downloadReportReducer = (did, dateRange, type, reportReturn) => async (dispatch) => {
    dispatch(slice.actions.setReportDownload({ ...initialState.reportDownloadData, status: "loading" }));
    get(API_BASE_URL + "phed/get_non_functional_excel", { did, from: dateRange[0], to: dateRange[1], type, reportReturn })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            if (response?.statusCode === 404) {
                toast.error(response?.message ? response?.message : "No reports found!");
                dispatch(slice.actions.setReportDownload({ ...initialState.reportDownloadData }));
                return
            }
            dispatch(slice.actions.setReportDownload(response));
        })
        .catch(function (errResponse) {
            toast(errResponse?.message ? errResponse?.message : "Error in fetching reports data!", { type: "error" });
            dispatch(slice.actions.setReportDownload({ ...errResponse, status: "failed" }))
        });
};

export const getDeadDevices = (did, dateRange) => async (dispatch) => {
    dispatch(slice.actions.setDeadDevices({ ...initialState.deadDevices, status: "loading" }));
    get(API_BASE_URL + "phed/get_dead_devices", { did, from: dateRange[0], to: dateRange[1] })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            if (response?.statusCode === 404) {
                toast.error(response?.message ? response?.message : "No dead devices found!");
                dispatch(slice.actions.setDeadDevices({ ...initialState.deadDevices }))
                return
            }
            dispatch(slice.actions.setDeadDevices(response));
        })
        .catch(function (errResponse) {
            toast(errResponse?.message ? errResponse?.message : "Error in fetching dead devices data!", { type: "error" });
            dispatch(slice.actions.setDeadDevices({ ...errResponse, status: "failed" }))
        });
};

export const getCurrentNFW = (did, wardType = "nonFunctionalWards") => async (dispatch) => {

    if (!did || !wardType) {
        return
    }

    dispatch(slice.actions.setCurrentNFW({ ...initialState.currentNFW, status: "loading" }));
    get(API_BASE_URL + "phed/get_current_report", { district: did, wardType })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setCurrentNFW(response));
        })
        .catch(function (errResponse) {
            toast(errResponse?.message ? errResponse?.message : "Error in fetching current nfw data!", { type: "error" });
            dispatch(slice.actions.setCurrentNFW({ ...errResponse, status: "failed" }))
        });
};

export const getDeleteLog = () => async (dispatch) => {
    dispatch(slice.actions.setDeleteLog({ ...initialState.downloadDeleteLog, status: "loading" }));
    get(API_BASE_URL + "phed/downloadDeleteLog")
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setDeleteLog(response));
        })
        .catch(function (errResponse) {
            toast(errResponse?.message ? errResponse?.message : "Error in fetching delete log data!", { type: "error" });
            dispatch(slice.actions.setDeleteLog({ ...errResponse, status: "failed" }))
        });
};

export default slice;