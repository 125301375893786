import { useRoutes } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import routes from "./routes";
import Loader from "./components/Loader/Loader";

const App = () => {
  const auth = useAuth();
  const content = useRoutes(routes);
  return <>{auth.isInitialized ? content : <Loader />}</>;
};

export default App;
