import { Box } from "@mui/material";

function Placeholder({ title = "This feature will be available soon!" }) {
  return (
    <>
      <Box
        sx={{
          p: 2,
          color: "#727cf5"
        }}
      >
        {title}
      </Box>
    </>
  );
}

export default Placeholder;
