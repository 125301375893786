import toast from "react-hot-toast";
import { API_BASE_URL } from "../config";
import { get, post } from "../slices/service";

export const generateNFReport = async (payload) => {
  try {
    const reportRes = await post(
      API_BASE_URL + "phed/generate-report",
      payload
    );
    if (reportRes.statusCode === 201 || reportRes.statusCode === 200) {
      toast.success(reportRes?.message || "Successfully report generated!", {
        duration: 4000,
      });
      return reportRes;
    } else if (reportRes.statusCode === 400) {
      toast.error(reportRes?.message || "Error in generating reports!", {
        duration: 4000,
      });
      return;
    } else {
      throw new Error(
        reportRes.message || "Error in generate-non-func-report!"
      );
    }
  } catch (error) {
    toast.error(
      error.message || "Error occurred in generate-non-func-report!",
      {
        duration: 4000,
      }
    );
  }
};


export const getReports = async ({ did, dateRange, type, reportReturn } = {}) => {
  try {
    if (!did || !dateRange || !type || !reportReturn) {
      throw new Error("Required parameters are missing!");
    }
    const response = await get(API_BASE_URL + "phed/get_non_functional_excel", {
      did,
      from: dateRange[0],
      to: dateRange[1],
      type,
      reportReturn
    });

    if (response.statusCode === 200) {
      return response;
    } else if (response.statusCode === 404) {
      toast.error(response.message || "No reports found!", { duration: 4000 });
      return;
    } else {
      throw new Error(response.message || "Error in fetching reports data!");
    }
  } catch (error) {
    toast.error(error.message || "Error in fetching reports data!", {
      duration: 4000,
    });
  }
};

export const getDeadDevices = async ({ did, dateRange }) => {
  try {
    if (!did || !dateRange) {
      throw new Error("Required parameters are missing");
    }
    const response = await get(API_BASE_URL + "phed/get_dead_devices", {
      did,
      from: dateRange[0],
      to: dateRange[1]
    });

    if (response.statusCode === 200) {
      return response;
    } else if (response.statusCode === 404) {
      toast.error(response.message || "No dead devices found!", { duration: 4000 });
      return;
    } else {
      throw new Error(response.message || "Error in fetching dead devices data!");
    }
  } catch (error) {
    toast.error(error.message || "Error in fetching dead devices data!", {
      duration: 4000,
    });
  }
};

export const getCurrentNFW = async ({ did = null, wardType = "nonFunctionalWards" } = {}) => {
  try {
    if (!did || !wardType) {
      throw new Error("District ID or ward type is missing");
    }
    const response = await get(API_BASE_URL + "phed/get_current_report", {
      district: did,
      wardType
    });
    if (response.statusCode === 200) {
      return response;
    } else {
      throw new Error(response.message || "Error in fetching current NFW data!");
    }
  } catch (error) {
    toast.error(error.message || "Error in fetching current NFW data!", {
      duration: 4000,
    });
  }
};


export const getDeleteLog = async () => {
  try {
    const response = await get(API_BASE_URL + "phed/downloadDeleteLog");

    if (response.statusCode === 200) {
      return response;
    } else {
      throw new Error(response.message || "Error in fetching delete log data!");
    }
  } catch (error) {
    toast.error(error.message || "Error in fetching delete log data!", {
      duration: 4000,
    });
  }
};


