import moment from "moment-timezone";

export const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

function currentDateTime(getWhat = "both") {
  const date = new Date();
  if (getWhat == "both") return date.toLocaleString();
  else if (getWhat == "date") {
    return date.toLocaleDateString();
  }
}

function convertToDate(datetime) {
  //datetime = datetime.replace(/-/g, "/")
  if (datetime) {
    var date = new Date(datetime);
  } else {
    var date = new Date();
  }
  var opDate = date.getTime() / 1000;
  return opDate;
}

// function convertToFilterDate(datetime, setToEnd = false) {
//   var date = new Date();
//   if (datetime) {
//     date = new Date(datetime);
//     if (setToEnd) {
//       //to set starting of next day
//       date.setDate(date.getDate() + 1);
//     }
//   }

//   date.setHours(0, 0, 0, 0);
//   var opDate = date.getTime() / 1000;
//   return opDate;
// }

function convertToFilterDate(datetime, setToEnd = false, isBefore12AM = false) {
  var date = new Date();
  if (datetime) {
    date = new Date(datetime);
    if (setToEnd) {
      date.setDate(date.getDate() + 1);
    }
  }

  date.setHours(0, 0, 0, 0);
  if (isBefore12AM) {
    date.setHours(23, 59, 0, 0);
  }
  var opDate = date.getTime() / 1000;
  return opDate;
}

function getDiffInMin(from, to) {
  return Math.abs(to - from) / 60;
}

function getDateTime(unixts) {
  let lrtime = moment.unix(unixts).format("h:mm:ss a");
  let lrdate = moment.unix(unixts).format("DD/MM/YYYY");
  return { date: lrdate, time: lrtime };
}

function convertNumToTime(number) {
  try {
    // Check sign of given number
    var sign = number >= 0 ? 1 : -1;

    // Set positive value of number of sign negative
    number = number * sign;

    // Separate the int from the decimal part
    var hour = Math.floor(number);
    var decpart = number - hour;

    var min = 1 / 60;
    // Round to nearest minute
    decpart = min * Math.round(decpart / min);

    var minute = Math.floor(decpart * 60) + "";

    // Add padding if need
    if (minute.length < 2) {
      minute = "0" + minute;
    }

    // Add Sign in final result
    sign = sign == 1 ? "" : "-";

    // Concate hours and minutes
    const time = sign + hour + ":" + minute;

    return time;
  } catch (E) {
    return "00:00";
  }
}

function historyDate(lastXdays = 5, type) {
  if (type != "currentDay") {
    var cDate = new Date();
    cDate.setHours(0, 0, 0, 0);
    const cTS = cDate.getTime();

    const lastDate = new Date();
    lastDate.setDate(lastDate.getDate() - lastXdays);
    // if(lastXdays == 0){
    lastDate.setHours(0, 0, 0, 0);
    // }
    const lastXDayTS = lastDate.getTime();

    return [lastXDayTS / 1000, cTS / 1000];
  } else {
    var cDate = new Date();
    const cTS = cDate.getTime();

    const lastDate = new Date();
    lastDate.setDate(lastDate.getDate() - lastXdays);
    // if(lastXdays == 0){
    lastDate.setHours(0, 0, 0, 0);
    // }
    const lastXDayTS = lastDate.getTime();

    return [lastXDayTS / 1000, cTS / 1000];
  }
}

//TODO: also add the 12, 24, 36, 48, 60, 72 hours time stamp
function get24HoursTimeStamp(lastXdays = 0) {
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - lastXdays);
  startDate.setHours(0, 0, 0, 0);
  const startTS = startDate.getTime();

  const endDate = new Date();
  endDate.setDate(endDate.getDate() - lastXdays + 1);
  endDate.setHours(0, 0, 0, 0);
  const endTS = endDate.getTime();
  return [startTS / 1000, endTS / 1000];
}

function checkIfRoleExist(userRoleString, allowedRoles) {
  let userRoles = userRoleString.split(",");
  return userRoles.some((e) => allowedRoles.includes(e));
}

const generateCapitalizedFilename = ({ name }) => {
  if (!name) return;
  const trimmedFilename = name.trim().replace(/_/g, " ");
  const words = trimmedFilename.split(/\s+/);
  const mergedWord = words.map((word) => word.toLowerCase()).join("");
  const capitalizedFilename =
    mergedWord.charAt(0).toUpperCase() + mergedWord.slice(1);
  return capitalizedFilename;
};

const capitalizedWordsFirstLetterWithSpace = (name) => {
  if (name?.length === 0) return;
  const trimmedFilename = name.trim().replace(/_/g, " ");
  const capitalizedWords = trimmedFilename
    .split(/\s+/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
  const capitalizedFilename = capitalizedWords.join(" ");
  const sanitizedFilename = capitalizedFilename.replace(/[^\w]/g, " ");
  return sanitizedFilename;
};

export const isSmsIdInBothArrays = ({
  smsid,
  nonFunctionalWards = [],
  twoDayNFWards = [],
}) => {
  const isInNonFunctionalWards = nonFunctionalWards.includes(smsid);
  const isInTwoDayNFWards = twoDayNFWards.includes(smsid);
  return isInNonFunctionalWards && isInTwoDayNFWards;
};

const removeFromArray = (actual = [], toRemove = []) => {
  return actual.filter((el) => !toRemove.includes(el));
};

// Utility function to deduplicate array elements
const deduplicateArray = (array) => {
  return [...new Set(array)];
};

const getCapitalizedFilename = ({ name }) => {
  if (!name) return "";
  const trimmedFilename = name.trim().replace(/_/g, " ");
  const words = trimmedFilename.split(/\s+/);
  const mergedWord = words.map((word) => word.toLowerCase()).join("");
  const capitalizedFilename =
    mergedWord.charAt(0).toUpperCase() + mergedWord.slice(1);
  return capitalizedFilename;
};

function formateDateTime(_ts, no_ts = "") {
  if (!_ts) return no_ts;
  let dateTime = moment.unix(_ts).format("DD-MMMM-YYYY : h:mm A");
  return dateTime;
}

const removeDuplicate = (arr, arr2) => {
  if (!arr || !arr2) return;
  return arr.filter((item) => !arr2.includes(item));
};

export function convertToJSON(inputString) {
  const keyValuePairs = inputString?.split(";");
  const jsonObject = {};
  keyValuePairs?.forEach((pair) => {
    const [key, value] = pair.split("=");
    if (key && value) {
      jsonObject[key] = isNaN(value) ? value : Number(value);
    }
  });
  return jsonObject;
}

export function hasProperties(obj) {
  if (obj != null && typeof obj === "object") {
    const keys = Object.keys(obj);
    return keys.length > 0 && keys.every(key => typeof key === "string" && key.trim().length > 0);
  }
  return false;
}

export const downloadFile = (url, fileName) => {
  const dt = new Date();
  const newDT = new Date(dt).getTime();
  const link = document.createElement("a");
  link.setAttribute("target", "_blank");
  link.href = `${url}?id=${newDT}`;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// Function to sync if any ward is unchecked
export const syncNFLists = ({ listIndex, listsName, fetchedIdsListName, updateFunctions }) => {
  for (let index = 0; index < listsName.length - 1; index++) {
    if (index !== listIndex) {
      updateFunctions[index](removeFromArray(listsName[index], removeFromArray(fetchedIdsListName[listIndex], listsName[listIndex])));
    }
  }
};

// Function to sync non-functional reports if accordion is opened
export const syncAccordionData = ({ listIndex, listsName, fetchedIdsListName, updateFunctions }) => {
  let allSelected = listsName.flat();
  allSelected = deduplicateArray(allSelected);

  let tempList = allSelected.filter((wardId) =>
    fetchedIdsListName[listIndex].includes(wardId)
  );

  tempList = deduplicateArray(tempList);
  updateFunctions[listIndex](tempList);
};


export const updateFinalNFWList = ({ listsName, fetchedListName, selectedFinal_NFW, setSelectedFinal_Ids, setSelectedFinal_Wards }) => {
  let tempWards = selectedFinal_NFW;
  for (let index = 0; index < fetchedListName.length; index++) {
    if (Array.isArray(listsName[3])) {
      let nfList = fetchedListName[index];
      nfList.forEach((ward) => {
        const wardIndex = tempWards.findIndex(
          (tempWard) => tempWard.id === ward.id
        );
        if (wardIndex > -1) {
          tempWards.splice(wardIndex, 1);
        }
      });
    }
  }

  listsName.forEach((nfList, index) => {
    if (Array.isArray(fetchedListName[index])) {
      nfList.forEach((wardId) => {
        const ward = fetchedListName[index].find((ward) => ward.id === wardId);
        if (ward) {
          tempWards.push(ward);
        }
      });
    }
  });

  // Deduplicate wards based on their IDs
  const wardIds = [...new Set(tempWards.map(({ id }) => id))];
  const filtered = tempWards.filter(
    (obj, index) => tempWards.findIndex((item) => item.id === obj.id) === index
  );

  setSelectedFinal_Ids(wardIds);
  setSelectedFinal_Wards(filtered);
};

export {
  convertToDate,
  historyDate,
  getDiffInMin,
  getDateTime,
  convertToFilterDate,
  convertNumToTime,
  currentDateTime,
  get24HoursTimeStamp,
  checkIfRoleExist,
  generateCapitalizedFilename,
  removeFromArray,
  capitalizedWordsFirstLetterWithSpace,
  getCapitalizedFilename,
  formateDateTime,
  removeDuplicate,
};
