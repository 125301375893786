import toast from "react-hot-toast";
import { API_BASE_URL } from "../config";
import wait from "../utils/wait";


class AuthApi {
  async login({ email, password }) {
    await wait(500);

    try {
      const options = {
        method: "POST",
        body: JSON.stringify({
          userName: email,
          password: password,
        }),
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      };

      const response = await fetch(API_BASE_URL + "auth/signin", options);
      const singRes = await response.json();

      if (singRes?.statusCode === 404) {
        toast.error(singRes?.message || "User not found! \n\nPlease create account first!");
        return;
      }

      return singRes.data;
    } catch (error) {
      toast.error(error?.message || "Something went wrong in login! \n\nPlease check your internet connectivity!");
      throw new Error("Internal server error");
    }
  }

  async register(data) {
    try {
      const options = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await fetch(API_BASE_URL + "auth/signup", options);
      return await response.json();
    } catch (error) {
      toast.error(error?.message || "Something went wrong in register!");
      throw new Error("Internal server error");
    }
  }

  async me(token) {
    try {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(API_BASE_URL + "auth/user", options);
      return await response.json();
    } catch (error) {
      toast.error(error?.message || "Something went wrong!");
      throw new Error("Internal server error");
    }
  }
}

export const authApi = new AuthApi();