import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const AdminGuard = ({ children }) => {
    const userType = localStorage.getItem("USER_TYPE");

    if (userType?.includes("APP_SUPER_ADMIN")) {
        return <>{children}</>;
    } else {
        return <Navigate to="/authentication/login" />;
    }
};

AdminGuard.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AdminGuard;
