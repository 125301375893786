import useSettings from "../../../hooks/useSettings";
import CommonButtonGroup, { createRenderContent } from "../../../components/common/CommonButtonGroup";
import DepartmentLayout from "../../../components/common/DepartmentLayout";

export const menus = [
  "Scheme Data",
  "Trigger Device Events",
  "Reports",
  "Calling Reports",
];

function Phed() {
  const { settings } = useSettings();
  const dept = "phed"
  const titleForPdf = "Public Health Department"
  const renderContent = createRenderContent(dept, titleForPdf);

  return (
    <DepartmentLayout settings={settings} dept={dept} >
      <CommonButtonGroup menus={menus} renderContent={renderContent} />
    </DepartmentLayout>
  );
}

export default Phed;
