import {
  Box,
  Container,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import React from "react";

function RegDevicesList({
  columns = [],
  rows = [],
  totalDocuments = 0,
  currentIndex = 0,
  fetchNext,
  fetchPrevious,
  hasMoreResults,
  pageSize = 40,
  isShowTable = false,
  isShowPagination = true
}) {
  return (
    <Paper sx={{ width: "100%" }}>
      {
        isShowTable && <TableContainer sx={{ maxHeight: 600, minHeight: 500, }}>
          <Table stickyHeader aria-label="sticky table" >
            <TableHead>
              <TableRow>
                {columns.map((cx) => {
                  if (cx?.hide) {
                    return null
                  }
                  return (
                    <TableCell
                      sx={{
                        textWrap: "nowrap",
                        minWidth: cx?.width
                      }}
                      key={cx.field}
                      align={cx.align}
                    >
                      {cx.headerName}
                    </TableCell>
                  )
                }
                )}
              </TableRow>
            </TableHead>
            {rows?.length === 0 ? (
              null
            ) : (
              <TableBody>
                {rows?.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      {columns.map((column) => {
                        if (column?.hide) {
                          return null
                        }
                        const value = row[column.field];
                        return (
                          <TableCell
                            key={column.field}
                            align={column.align}
                          >
                            {column.renderCell
                              ? column.renderCell({
                                value,
                                formattedValue: value,
                                row,
                              })
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
          {
            rows?.length === 0 && (
              <Box
                sx={{
                  width: "100%",
                  height: 500,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography>No record found!</Typography>
              </Box>
            )}
        </TableContainer>
      }
      {
        isShowPagination &&
        <Container>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              height: 40,
            }}
          >
            <Typography>
              {`${rows.length === 0 ? 0 : pageSize * currentIndex + 1
                } - ${(pageSize * currentIndex) + rows.length} of ${totalDocuments}`}
            </Typography>
            <Stack direction="row" spacing={1}>
              <IconButton
                disabled={currentIndex === 0}
                aria-label="pagination"
                onClick={fetchPrevious}
              >
                <KeyboardArrowLeft fontSize="inherit" />
              </IconButton>
              <IconButton
                disabled={hasMoreResults}
                aria-label="pagination"
                onClick={fetchNext}
              >
                <KeyboardArrowRight fontSize="inherit" />
              </IconButton>
            </Stack>
          </Box>
        </Container>
      }
    </Paper>
  );
}

export default RegDevicesList;
